import { Translation } from 'react-i18next'

import { segmentNameRenderer } from 'components/FieldRenderers/Renderers/segment'
import {
    CategoryListRenderer,
    SegmentProductNameRenderer,
    TitleRenderer,
} from 'components/FieldRenderers/Renderers/segmentProduct'
import { Field } from 'types'

import {
    createCurrencyField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function asin<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['asin']
    return createField({
        ...options,
        id: 'asin',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 0,
    })
}

export function productInfo<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'segment_product_id',
        name: 'Product',
        shortName: 'Product',
        minWidth: options.minWidth ?? 100,
        dataIndex: options.dataIndex ?? ['product_id'],
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({
                    ...props,
                    showImage: options.showImage,
                }),
        },
        width: 300,
    })
}

export function title<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'segment_product_title',
        name: 'Product',
        shortName: 'Product',
        minWidth: options.minWidth ?? 80,
        dataIndex: options.dataIndex ?? ['title'],
        renderOptions: {
            render: (props) =>
                TitleRenderer<any>({ ...props, showImage: options.showImage }),
        },
        width: 300,
        exportIndex: 7,
    })
}

export function brand<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['brand']
    return createField({
        ...options,
        id: 'brand',
        name: 'Brand',
        shortName: 'Brand',
        minWidth: options.minWidth ?? 100,
        dataIndex,
        width: options.width ?? 180,
        exportIndex: 3,
    })
}

export function price<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createCurrencyField({
        ...options,
        id: 'segment_product_price',
        name: 'Price',
        shortName: 'Price',
        minWidth: 40,
        dataIndex: options.dataIndex ?? ['price'],
        align: 'right',
    })
}

export function seller_id<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_id']
    return createField({
        ...options,
        id: 'seller_id',
        name: 'Seller ID',
        shortName: 'Seller ID',
        minWidth: 100,
        dataIndex,
        exportIndex: 4,
    })
}

export function seller_type<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_type']
    return createField({
        ...options,
        id: 'seller_type',
        name: 'Seller Type',
        shortName: 'Seller Type',
        minWidth: 100,
        dataIndex,
    })
}

export function category<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['category']
    return createField({
        ...options,
        id: 'category',
        name: 'Parent Category',
        shortName: 'Category',
        minWidth: 50,
        dataIndex,
        width: 80,
        columnTitle: 'Parent Category',
        exportIndex: 5,
    })
}

export function sub_category<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sub_category']
    return createField({
        ...options,
        id: 'sub_category',
        name: 'Primary Subcategory',
        shortName: 'Subcategory',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 6,
    })
}

export function subcategoriesList<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['subcategories']
    return createField({
        ...options,
        id: 'sub_categories',
        name: ' All Subcategories',
        shortName: 'Subcategories',
        minWidth: 50,
        dataIndex,
        renderOptions: {
            render: (props) => CategoryListRenderer<any>(props),
        },
        width: 100,
    })
}

export function estimatedRevenue<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['estimated_revenue']
    const name = (
        <Translation>
            {(t) => t('segments:estimatedRevenue', '30-Day Estimated Revenue')}
        </Translation>
    )
    return createCurrencyField({
        ...options,
        id: 'segment_product_estimated_revenue',
        name,
        shortName: 'Revenue',
        minWidth: 50,
        dataIndex,
        width: 70,
        align: 'right',
    })
}

export function estimatedSales<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['estimated_sales']
    const name = (
        <Translation>
            {(t) => t('segments:estimatedUnits', '30-Day Estimated Units')}
        </Translation>
    )
    return createNumberField({
        id: 'segment_product_estimated_sales',
        name,
        shortName: 'Est Units',
        isResizeable: true,
        minWidth: 50,
        isVisible: true,
        dataIndex,
        width: 70,
        align: 'right',
    })
}

export function productId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_id']
    const name = (
        <Translation>{(t) => t('common:product', 'Product')}</Translation>
    )
    return createField({
        ...options,
        id: 'product_id',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) =>
                SegmentProductNameRenderer<any>(
                    {
                        cellRenderProps: props,
                    },
                    ['product']
                ),
        },
        width: 300,
    })
}

export function segmentId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['segment_id']
    const name = (
        <Translation>{(t) => t('common:segment', 'Segment')}</Translation>
    )
    return createField({
        ...options,
        id: 'segment_id',
        name,
        shortName: name,
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) =>
                segmentNameRenderer<any>(
                    {
                        cellRenderProps: props,
                    },
                    ['segment']
                ),
        },
        width: 100,
    })
}

export function reviews<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>{(t) => t('segments:reviews', 'Reviews')}</Translation>
    )
    return createNumberField({
        ...options,
        id: 'segment_product_reviews',
        name,
        shortName: 'Reviews',
        minWidth: 40,
        dataIndex: options.dataIndex ?? ['reviews'],
        align: 'right',
    })
}

export function variantInfo<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['variant_info']
    return createField({
        ...options,
        id: 'variant_info',
        name: 'Variant Info',
        shortName: 'Variant Info',
        minWidth: 50,
        dataIndex,
        width: 100,
        exportIndex: 1,
    })
}

export function variantGroup<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['variant_group']
    return createField({
        ...options,
        id: 'variant_group',
        name: 'Variant Group',
        shortName: 'Variant Group',
        minWidth: 50,
        width: 100,
        dataIndex,
        exportIndex: 2,
    })
}

export function categoryBsr<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['category_rank__last_value']
    return createField({
        ...options,
        id: 'categoryBsr',
        name: 'Category BSR',
        shortName: 'Category BSR',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}

export function subcategoryBsr<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['subcategory_rank__last_value']
    return createField({
        ...options,
        id: 'subcategoryBsr',
        name: 'Subcategory BSR',
        shortName: 'Subcategory BSR',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}

export function imageUrl<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['image_url']
    return createField({
        ...options,
        id: 'imageUrl',
        name: 'Image',
        shortName: 'Image',
        minWidth: 50,
        dataIndex,
        width: 80,
    })
}
