import { ReactElement } from 'react'

import { Select, Spin } from 'antd'

import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getKeywords } from 'services/cerebroApi/orgScope/resourceApi'
import { Keyword } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
}

const KeywordSearchSelect = ({ value, onChange }: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<Keyword>({
            apiSearchFunc: (query) =>
                getKeywords(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        text__similar: query,
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (keyword) => ({
                value: keyword.id,
                label: keyword.text,
                metadata: keyword.campaign.name,
            }),
        })

    return (
        <Select
            filterOption={false}
            placeholder="Search Keywords..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={{ width: '100%' }}
            value={value}
        >
            {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label} <i>({option.metadata})</i>
                </Select.Option>
            ))}
        </Select>
    )
}

export default KeywordSearchSelect
