// This is the last remaining data source constant, used in a number of js files.
// Once those are converted to TypeScript, this can be removed.
// Prefer using string union types instead of constants going forward.
export const SEGMENT_FACTS = 'segment_facts'

type AmsStreamDataSourceKey = 'ams_stream_facts'

type OrgDataSourceKey = 'org_facts'

export type InsightDataSourceKey =
    | 'campaign_keyword_opportunity'
    | 'campaign_negative_keyword_opportunity'
    | 'campaign_roi_automation_opportunity'
    | 'tip'

type AdvertisingDataSourceKey =
    | 'campaign_facts'
    | 'keyword_facts'
    | 'product_ad_facts'
    | 'target_facts'
    | 'sb_purchased_product_facts'
    | AmsStreamDataSourceKey

type AdGroupDataSourceKey = 'ad_group_facts'

type DynamicAdvertisingDataSourceKey =
    | 'campaign_placement_facts' // adjusted dynamically when groupBy includes 'placement'
    | 'keyword_query_facts' // adjusted dynamically when groupBy includes 'query' or a search term filter
    | 'target_query_facts' // adjusted dynamically when groupBy includes 'query' or a search term filter

type DspDataSourceKey = 'dsp_campaign_facts' | 'dsp_inventory_facts'
type MarketIntelligenceDataSourceKey =
    | 'sov_search_results'
    | typeof SEGMENT_FACTS

type SellerCentralDataSourceKey = 'sc_sales_and_traffic' | 'sc_all_orders'

export type VendorCentralDiagnosticDataSourceKey =
    | 'vc_api_sales_diagnostic'
    | 'vc_api_sales_diagnostic_sourcing'
    | 'vc_api_inventory_manufacturing'
    | 'vc_api_inventory_sourcing'

type VendorCentralDataSourceKey =
    | VendorCentralDiagnosticDataSourceKey
    | 'vc_api_inventory_health'

export type LegacyVendorCentralDataSourceKey =
    | 'vc_sales_diagnostic'
    | 'vc_source_sales_diagnostic'
    | 'vc_inventory_health'
    | 'vc_traffic_diagnostic'

type WalmartDataSourceKey =
    | 'wm_advertising_campaign_facts'
    | 'wm_advertising_placement_facts'
    | 'wm_advertising_platform_facts'
    | 'wm_advertising_item_facts'
    | 'wm_advertising_keyword_facts'
    | 'wm_marketplace_order_facts' // deprecated

type DownstreamDataSourceKey = 'changelog' | 'asin_usage'

type AlertOnlyDataSourceKey =
    | 'sov_keyword_facts'
    | 'wm_advertising_ad_group_facts'
    | 'wm_advertising_ad_item_facts'
    | 'dsp_line_item_facts'
    | 'vc_sales_diagnostic_facts'
    | 'vc_inventory_health_facts'
    | 'vc_inventory_manufacturing_facts'
    | 'vc_inventory_sourcing_facts'
    | 'vc_sales_diagnostic_sourcing_facts'
    | 'portfolio_facts'
    | 'dsp_order_facts'

export type AlertDataSourceKey =
    | AlertOnlyDataSourceKey
    | Exclude<
          AdvertisingDataSourceKey,
          | 'product_ad_facts'
          | AmsStreamDataSourceKey
          | 'sb_purchased_product_facts'
      >
    | 'wm_advertising_campaign_facts'
    | 'wm_advertising_keyword_facts'
    | AdGroupDataSourceKey

export type RulebookDataSourceKey = Exclude<
    AlertDataSourceKey,
    'sov_keyword_facts'
>

type DataSourceKey =
    | OrgDataSourceKey
    | AdvertisingDataSourceKey
    | AlertDataSourceKey
    | DynamicAdvertisingDataSourceKey
    | DspDataSourceKey
    | MarketIntelligenceDataSourceKey
    | SellerCentralDataSourceKey
    | VendorCentralDataSourceKey
    | WalmartDataSourceKey
    | LegacyVendorCentralDataSourceKey
    | DownstreamDataSourceKey
    | '' // used for text widget data source

export type WidgetDataSourceKey = Exclude<DataSourceKey, AlertOnlyDataSourceKey>

export type FilterableDataSourceKey = Exclude<
    WidgetDataSourceKey,
    | 'ad_group_facts'
    | 'campaign_placement_facts'
    | 'keyword_query_facts'
    | 'target_query_facts'
    | ''
>

// Datasource keys that we have integration info
// for in /api/organization/{orgId}/account_status
export type OrgAccountStatusDataSourceKey =
    | AdvertisingDataSourceKey
    | DspDataSourceKey
    | MarketIntelligenceDataSourceKey
    | SellerCentralDataSourceKey
    | VendorCentralDataSourceKey
    | LegacyVendorCentralDataSourceKey
    | Exclude<WalmartDataSourceKey, 'wm_marketplace_order_facts'>

// Validate keys that we have integration info for
// so we can narrow widget data source types to those we have info for
export const isKeyInOrgAccountStatusDataSources = (
    dataSource: WidgetDataSourceKey
): dataSource is OrgAccountStatusDataSourceKey => {
    return (
        dataSource === 'campaign_facts' ||
        dataSource === 'keyword_facts' ||
        dataSource === 'product_ad_facts' ||
        dataSource === 'target_facts' ||
        dataSource === 'ams_stream_facts' ||
        dataSource === 'dsp_campaign_facts' ||
        dataSource === 'dsp_inventory_facts' ||
        dataSource === 'sov_search_results' ||
        dataSource === 'segment_facts' ||
        dataSource === 'sc_sales_and_traffic' ||
        dataSource === 'sc_all_orders' ||
        dataSource === 'vc_api_sales_diagnostic' ||
        dataSource === 'vc_api_sales_diagnostic_sourcing' ||
        dataSource === 'vc_api_inventory_health' ||
        dataSource === 'vc_sales_diagnostic' ||
        dataSource === 'vc_source_sales_diagnostic' ||
        dataSource === 'vc_traffic_diagnostic' ||
        dataSource === 'vc_inventory_health' ||
        dataSource === 'wm_advertising_campaign_facts' ||
        dataSource === 'wm_advertising_placement_facts' ||
        dataSource === 'wm_advertising_platform_facts' ||
        dataSource === 'wm_advertising_item_facts' ||
        dataSource === 'wm_advertising_keyword_facts' ||
        dataSource === 'sb_purchased_product_facts'
    )
}
