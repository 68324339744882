import { captureException } from '@sentry/browser'
import { AxiosResponse } from 'axios'
import { PropertyPath } from 'lodash'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import noop from 'lodash/noop'
import set from 'lodash/set'
import { generatePath } from 'react-router-dom'
import { Action } from 'redux-actions'
import { Task } from 'redux-saga'
import {
    all,
    call,
    CallEffect,
    fork,
    join,
    put,
    select,
    spawn,
} from 'redux-saga/effects'

import {
    acceptInvitationFailure,
    acceptInvitationSuccess,
    acceptSignUpFormInvitationFailure,
    acceptSignUpFormInvitationSuccess,
    changeOrganizationFailure,
    changeOrganizationGroupFailure,
    createAuthPageOrganizationFailure,
    createAuthPageOrganizationSuccess,
    fetchLatestOrganizationAdIntegrationsSuccess,
    fetchLatestOrganizationIntegrationSuccess,
    fetchLatestWalmartAdvertiserSuccess,
    fetchPendingInvitationsSuccess,
    fetchProfileFeaturePermissionsSuccess,
    fetchUserFeaturePermissionsSuccess,
    fetchUserOrganizationFeaturePermissionsSuccess,
    fetchUserSettingsSuccess,
    getSignUpFormInvitationFailure,
    getSignUpFormInvitationSuccess,
    impersonationModeFailure,
    loadAuthFailure,
    loadAuthFinish,
    resendCodeSuccess,
    resendSignUpCodeSuccess,
    resetPasswordFailure,
    sendResetEmailFailure,
    sendResetEmailSuccess,
    setOrganizationId,
    setUserIsImpersonating,
    signInFailure,
    signInMfaRequired,
    signInRequest,
    signInSuccess,
    signOutFailure,
    signOutSuccess,
    signUpFailure,
    signUpSuccess,
    verifyEmailFailure,
} from 'actions/auth'
import { mountAppRequest, setGlobalNotification } from 'actions/ui/app'
import {
    CEREBRO_ACCESS_TOKEN,
    CEREBRO_REFRESH_TOKEN,
    COGNITO_ACCESS_TOKEN,
} from 'const/localStorage'
import {
    AUTH_ORGANIZATION_PAGE,
    AUTH_PAGE,
    AUTH_RESET_PASSWORD_PAGE,
    AUTH_SIGN_IN_MFA_PAGE,
    AUTH_VERIFY_EMAIL_PAGE,
    HOME_PAGE,
} from 'const/pages'
import {
    userHasAssumeAnyUserPermissions,
    userHasCustomerServicePermissions,
    userHasWalmartAdvertisingReadPermissions,
} from 'helpers/featurePermissions'
import { generateCodeSentNotification } from 'helpers/notifications'
import { getPath } from 'helpers/pages'
import { formatSignUpData, SignupData } from 'helpers/params'
import {
    sendTrackEventSaga,
    setDebugUserContextSaga,
    setThirdPartyTrackingSaga,
    unsetDebugUserContextSaga,
} from 'sagas/auth/thirdParty'
import { cerebroApiSaga } from 'sagas/common'
import { fetchOrganizationGroupsSaga } from 'sagas/orgs/groups/workers'
import { fetchOrganizationsSaga } from 'sagas/orgs/organizations/workers'
import {
    selectDomainValue as selectAuthDomainValue,
    selectProfileFeaturePermissions,
    selectUserFeaturePermissions,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import {
    checkUserNotConfirmed,
    confirmSignUp,
    login,
    loginMfa,
    resendSignUpCode,
    resetPassword,
    sendResetEmail,
    signUp,
} from 'services/cerebroApi/noScope/authApi'
import {
    getCurrentUser,
    getForgeSettings,
    getInvitations,
    getMfaSettingsAccessToken,
    getOrganization,
    getUserFeaturePermissions,
    getUserOrganizationFeaturePermissions,
    getUserOrganizationGroupFeaturePermissions,
    getUserOrganizationGroups,
    getUserOrganizations,
    getUserSettings,
    patchInvitation,
    updateUserSettings,
} from 'services/cerebroApi/noScope/resourceApi'
import {
    createOrganization,
    getAdAccounts,
    getInvitationByToken,
    getOrganizationIntegrations,
    getProfileFeaturePermissions,
} from 'services/cerebroApi/orgScope/resourceApi'
import { getWalmartAdvertisers } from 'services/cerebroApi/orgScope/walmartApi'
import {
    clearSegmentTraits,
    getIdentifyProps,
    sendSegmentTrackAndIdentifyEvents,
} from 'services/segment'
import {
    registerChurnZero,
    registerPendoUser,
    stopChurnZero,
} from 'services/userTracking'
import {
    ForgeSettings,
    Organization,
    OrganizationGroup,
    PaginatedResponse,
    ProfilePermission,
    User,
    UserPermission,
    UserSettings,
} from 'types'
import { createStorableAccessToken } from 'types/auth'
import { CognitoAccessToken, Login, Tokens } from 'types/resources/auth'
import history from 'utilities/history'
import moment from 'utilities/moment'
import { MfaSessionExpiredError } from 'views/Auth/Errors/MfaSessionExpiredError'
import { SmsMfaChallengeError } from 'views/Auth/Errors/SmsMfaChallengeError'

interface SettingPair {
    path: PropertyPath
    value: any
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* setCerebroAuthTokensSaga(
    accessToken: Login['access_token'],
    refreshToken: Login['refresh_token']
) {
    yield all([
        call([localStorage, 'setItem'], CEREBRO_ACCESS_TOKEN, accessToken),
        call([localStorage, 'setItem'], CEREBRO_REFRESH_TOKEN, refreshToken),
    ])
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* deleteCerebroAuthTokensSaga() {
    yield all([
        call([localStorage, 'removeItem'], CEREBRO_ACCESS_TOKEN),
        call([localStorage, 'removeItem'], CEREBRO_REFRESH_TOKEN),
        call([localStorage, 'removeItem'], COGNITO_ACCESS_TOKEN),
    ])
}

/**
 * Login with Cerebro and get auth tokens
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* authorizeCerebroSaga(email: string, password: string) {
    const response: AxiosResponse<Login> = yield call(login, email, password)

    if (
        response.status === 302 &&
        has(response, ['data', 'code']) &&
        response.data.code === 'SmsChallenge'
    ) {
        const tokens = response.data.tokens as Tokens
        throw new SmsMfaChallengeError(
            'SMS Challenge required',
            email,
            password,
            tokens
        )
    }

    if (response.status !== 200) {
        if (has(response, ['data', 'error_description'])) {
            throw new Error(get(response, ['data', 'error_description']))
        } else if (has(response, ['data', 'error'])) {
            throw new Error(get(response, ['data', 'error']))
        }
    }
    // Save auth tokens to local storage
    const { access_token, refresh_token } = response.data
    yield call(setCerebroAuthTokensSaga, access_token, refresh_token)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* setCognitoAuthTokensSaga(email: string, password: string) {
    const response: AxiosResponse<CognitoAccessToken> = yield call(
        getMfaSettingsAccessToken,
        email,
        password
    )

    if (response.status === 200) {
        const accessToken = createStorableAccessToken(
            response.data.access_token
        )

        yield all([
            call(
                [localStorage, 'setItem'],
                COGNITO_ACCESS_TOKEN,
                JSON.stringify(accessToken)
            ),
        ])
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* authorizeCerebroMfaSaga(
    email: string,
    password: string,
    tokens: Tokens,
    code: string
) {
    const response: AxiosResponse<Login> = yield call(
        loginMfa,
        email,
        password,
        tokens,
        code
    )

    if (
        response.status === 401 &&
        has(response, ['data', 'code']) &&
        response.data.code === 'SessionExpired'
    ) {
        throw new MfaSessionExpiredError('Code Expired')
    }

    if (response.status !== 200) {
        if (has(response, ['data', 'error_description'])) {
            throw new Error(get(response, ['data', 'error_description']))
        } else if (has(response, ['data', 'error'])) {
            throw new Error(get(response, ['data', 'error']))
        }
    }

    // Save auth tokens to local storage
    const { access_token, refresh_token } = response.data
    yield call(setCerebroAuthTokensSaga, access_token, refresh_token)
}

function isAuthenticated(): boolean {
    return !!localStorage.getItem(CEREBRO_ACCESS_TOKEN)
}

/**
 * Fetch the current user's profile, doesn't error out
 */
function* fetchCurrentUserSaga(): Generator<
    CallEffect<AxiosResponse<User>>,
    AxiosResponse<User> | null,
    AxiosResponse<User>
> {
    try {
        if (!isAuthenticated()) {
            return null
        }
        return yield call(getCurrentUser)
    } catch (_error) {
        return null
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchUserSettingsSaga() {
    let userSettings: UserSettings = yield select(
        selectAuthDomainValue,
        'settings'
    )

    // Cache settings for 1 hour
    if (
        !userSettings ||
        userSettings.lastUpdated.isBefore(moment().subtract(1, 'hour'))
    ) {
        userSettings = yield call(getUserSettings)
        yield put(
            fetchUserSettingsSuccess({
                ...userSettings,
                lastUpdated: moment(),
            })
        )
    }

    return userSettings
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* updateUserSettingsSaga(settingPairs: SettingPair[]) {
    if (!settingPairs || settingPairs.length === 0) {
        return
    }

    let userSettings: UserSettings = yield call(fetchUserSettingsSaga)
    settingPairs.forEach((each) => {
        userSettings = set(userSettings, each.path, each.value)
    })

    yield put(
        fetchUserSettingsSuccess({
            ...userSettings,
            lastUpdated: moment(),
        })
    )
    yield spawn(updateUserSettings, userSettings)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchPendingInvitationsSaga(email: User['email']) {
    yield call(cerebroApiSaga, fetchPendingInvitationsSuccess, getInvitations, {
        state: 'pending',
        user_email: email,
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchUserFeaturePermissionsSaga() {
    const result: AxiosResponse<UserPermission[]> = yield call(
        cerebroApiSaga,
        fetchUserFeaturePermissionsSuccess,
        getUserFeaturePermissions
    )
    return result
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchLatestActiveOrganizationIntegrationSaga() {
    const organizationId: string = yield select(
        selectAuthDomainValue,
        'organizationId'
    )

    yield call(
        cerebroApiSaga,
        fetchLatestOrganizationIntegrationSuccess,
        getOrganizationIntegrations,
        organizationId,
        {
            limit: 1,
            offset: 0,
            ordering: '-created_at',
            active: true,
        }
    )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchLatestWalmartAdvertiserSaga() {
    const hasPermission = userHasWalmartAdvertisingReadPermissions(
        yield select(selectUserOrganizationFeaturePermissions)
    )

    if (hasPermission) {
        yield call(
            cerebroApiSaga,
            fetchLatestWalmartAdvertiserSuccess,
            getWalmartAdvertisers,
            {
                limit: 1,
                offset: 0,
                ordering: '-created_at',
                active: 1,
                read_only: false,
            }
        )
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchLatestOrganizationAdIntegrationsSaga() {
    const apiParams: any = {
        ordering: '-updated_date',
        type__in: 'seller,vendor',
    }

    yield call(
        cerebroApiSaga,
        fetchLatestOrganizationAdIntegrationsSuccess,
        getAdAccounts,
        apiParams
    )
}

/**
 * Signal that sign in has finished and set up debugging and third party tracking
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* saveCurrentUserToAppSaga({
    currentUser,
    organization = null,
    organizationGroup = null,
    gilId,
}: {
    currentUser: User
    organization: Organization | null
    organizationGroup: OrganizationGroup | null
    gilId?: string
}) {
    // Set context for Sentry and Datadog
    const setupDebugTask: Task = yield fork(
        setDebugUserContextSaga,
        currentUser.username,
        currentUser.email,
        organization,
        organizationGroup
    )

    // Set context for third party tracking
    yield spawn(setThirdPartyTrackingSaga, currentUser, organization, gilId)

    // Store current user
    yield put(
        signInSuccess({
            currentUser,
            organizationId: organization?.id,
            organizationGroupId: organizationGroup?.id,
        })
    )

    yield join(setupDebugTask)
}

/**
 * Fetch feature permissions for the organization
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchUserOrganizationFeaturePermissionsSaga({
    userFeaturePermissions,
    organizationId,
    organizationGroupId,
}: {
    userFeaturePermissions?: UserPermission[]
    organizationId?: Organization['id']
    organizationGroupId?: OrganizationGroup['id']
}) {
    const isImpersonating: boolean = yield select(
        selectAuthDomainValue,
        'userIsImpersonating'
    )
    const isCustomerService = userFeaturePermissions
        ? userHasCustomerServicePermissions(userFeaturePermissions)
        : userHasCustomerServicePermissions(
              yield select(selectUserFeaturePermissions)
          )

    // For customer service users, use the permissions of the organization group
    // This is required because the user is likely not a member of the organization
    if (isImpersonating && isCustomerService) {
        const orgGroupId: number = isNull(organizationGroupId)
            ? yield select(selectAuthDomainValue, 'organizationGroupId')
            : organizationGroupId

        yield call(
            cerebroApiSaga,
            fetchUserOrganizationFeaturePermissionsSuccess,
            getUserOrganizationGroupFeaturePermissions,
            orgGroupId
        )
    } else {
        yield call(
            cerebroApiSaga,
            fetchUserOrganizationFeaturePermissionsSuccess,
            getUserOrganizationFeaturePermissions,
            organizationId
        )
    }
}

/**
 * Get the fallback organization group when it couldn't be retrieved from the user's settings
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getFallbackOrganizationGroupSaga() {
    let organization = null
    let organizationGroup = null

    try {
        const {
            data: { results },
        }: AxiosResponse<PaginatedResponse<OrganizationGroup>> = yield call(
            getUserOrganizationGroups,
            {
                limit: 1,
            }
        )
        if (!isEmpty(results)) {
            organization = get(results, ['0', 'organization'])
            organizationGroup = {
                ...get(results, ['0']),
                organization: undefined,
            }
        }
    } catch (err: any) {
        console.error(err)
    }

    return {
        organization,
        organizationGroup,
    }
}

/**
 * Get the fallback organization when it couldn't be retrieved from the user's settings
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getFallbackOrganizationSaga() {
    let organization = null

    try {
        const {
            data: { results },
        }: AxiosResponse<PaginatedResponse<Organization>> = yield call(
            getUserOrganizations,
            { limit: 1 }
        )
        if (!isEmpty(results)) {
            organization = get(results, ['0'])
        }
    } catch (err: any) {
        console.error(err)
    }

    return organization
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getActiveOrganizationAndGroupForImpersonatingUserSaga() {
    let organization = null
    let organizationGroup = null

    const storedOrgGroupId: number = yield select(
        selectAuthDomainValue,
        'organizationGroupId'
    )

    let useFallbackOrgGroup = false
    if (!storedOrgGroupId) {
        useFallbackOrgGroup = true
    } else {
        // Impersonation users can query for any organization group
        const {
            data: { count, results },
        }: AxiosResponse<PaginatedResponse<OrganizationGroup>> = yield call(
            getUserOrganizationGroups,
            { id: storedOrgGroupId }
        )

        if (count === 0) {
            useFallbackOrgGroup = true
        } else {
            // Use the saved org group if it still exists
            organization = results[0].organization
            organizationGroup = {
                ...results[0],
                organization: undefined,
            }
        }
    }

    if (useFallbackOrgGroup) {
        const {
            organization: fallbackOrganization,
            organizationGroup: fallbackOrganizationGroup,
        } = yield call(getFallbackOrganizationGroupSaga)

        organization = fallbackOrganization
        organizationGroup = fallbackOrganizationGroup
    }

    return { organization, organizationGroup }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getActiveOrganizationForStandardUserSaga(userSettings: UserSettings) {
    let organization: Organization | null = null

    const savedOrgId = userSettings.organizationId

    let useFallbackOrg = false
    if (!savedOrgId) {
        useFallbackOrg = true
    } else {
        // Try to access the saved organization
        try {
            const { data }: AxiosResponse<Organization> = yield call(
                getOrganization,
                savedOrgId
            )
            organization = data
        } catch {
            useFallbackOrg = true
        }
    }

    if (useFallbackOrg) {
        organization = yield call(getFallbackOrganizationSaga)
    }

    const userSettingsUpdates: SettingPair[] = []
    if (
        organization &&
        userSettings.organizationId?.toString() !== organization.id?.toString()
    ) {
        userSettingsUpdates.push({
            path: ['organizationId'],
            value: organization.id,
        })
    }
    if (userSettings.organizationGroupId !== null) {
        userSettingsUpdates.push({
            path: ['organizationGroupId'],
            value: null,
        })
    }

    // Sync with the database if organization or organization group has changed
    if (userSettingsUpdates.length > 0) {
        yield spawn(updateUserSettingsSaga, userSettingsUpdates)
    }

    return { organization, organizationGroup: null }
}

/**
 * Get the active organization and group for the current user,
 * based on the user's permissions and the current impersonation state
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getActiveOrganizationAndGroupSaga(
    isImpersonating: boolean,
    userSettingsTask: Task<UserSettings>,
    userPermissionsTask: Task<AxiosResponse<UserPermission[]>> | null = null
) {
    let isStandardUser = true
    let organization = null
    let organizationGroup = null

    if (isImpersonating) {
        let userFeaturePermissions: UserPermission[]
        if (userPermissionsTask) {
            const { data: permissions }: AxiosResponse<UserPermission[]> =
                yield join(userPermissionsTask)
            userFeaturePermissions = permissions
        } else {
            userFeaturePermissions = yield select(
                selectAuthDomainValue,
                'userFeaturePermissions'
            )
        }

        const isCustomerService = userHasCustomerServicePermissions(
            userFeaturePermissions
        )

        // User needs to be impersonating and also have the appropriate permissions to impersonate
        if (isCustomerService) {
            isStandardUser = false
            const result: {
                organization: Organization
                organizationGroup: OrganizationGroup
            } = yield call(
                getActiveOrganizationAndGroupForImpersonatingUserSaga
            )
            organization = result.organization
            organizationGroup = result.organizationGroup
        }
    }

    if (isStandardUser) {
        const userSettings: UserSettings = yield join(userSettingsTask)
        const result: {
            organization: Organization
            organizationGroup: OrganizationGroup
        } = yield call(getActiveOrganizationForStandardUserSaga, userSettings)
        organization = result.organization
        organizationGroup = result.organizationGroup
    }

    const userSettings: UserSettings = yield join(userSettingsTask)
    return {
        organization,
        organizationGroup,
        userSettings,
    }
}

/**
 * Finish the sign-in process by fetching the authorized user,
 * their feature permissions, and their current organization
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* tryFinishSignInSaga() {
    const result: AxiosResponse<User> | null = yield call(fetchCurrentUserSaga)

    if (result?.status !== 200) {
        return
    }

    const currentUser = result.data

    // Since we have an authorized user, we can start fetching some data in parallel
    const userSettingsTask: Task<UserSettings> = yield fork(
        fetchUserSettingsSaga
    )
    const userPermissionsTask: Task<AxiosResponse<UserPermission[]>> =
        yield fork(fetchUserFeaturePermissionsSaga)
    yield spawn(fetchOrganizationsSaga)
    yield spawn(fetchPendingInvitationsSaga, currentUser.email)

    const isImpersonating: boolean = yield select(
        selectAuthDomainValue,
        'userIsImpersonating'
    )

    const { organization, organizationGroup, userSettings } = yield call(
        getActiveOrganizationAndGroupSaga,
        isImpersonating,
        userSettingsTask,
        userPermissionsTask
    )

    const saveUserTask: Task = yield fork(saveCurrentUserToAppSaga, {
        currentUser,
        organization,
        organizationGroup,
        gilId: userSettings.gilId,
    })

    const { data: userFeaturePermissions } = yield join(userPermissionsTask)
    if (!organization) {
        // Send them to the auth organization page so they are assigned an organization
        if (!userHasAssumeAnyUserPermissions(userFeaturePermissions)) {
            history.push(getPath(AUTH_ORGANIZATION_PAGE))
        }
    } else {
        yield spawn(fetchOrganizationGroupsSaga, organization.id)
        yield call(fetchUserOrganizationFeaturePermissionsSaga, {
            userFeaturePermissions,
            organizationId: organization.id,
            organizationGroupId: organizationGroup?.id,
        })
    }

    yield join(saveUserTask)
}

/**
 * Authentication entry point for the application
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* loadAuthWorker() {
    try {
        // Load maintenance page setting
        const {
            displayDowntimePage,
            displayMaintenancePage,
            globalNotification,
        }: ForgeSettings = yield call(getForgeSettings)
        yield put(setGlobalNotification(globalNotification))

        if (displayMaintenancePage || displayDowntimePage) {
            const result: object = yield put(
                loadAuthFinish({ displayMaintenancePage, displayDowntimePage })
            )
            return result
        }

        // Try to finish the sign-in process if we have an authorized user
        yield call(tryFinishSignInSaga)

        const result: object = yield put(
            loadAuthFinish({ displayMaintenancePage })
        )
        return result
    } catch (err: any) {
        yield call(captureException, err)
        yield put(loadAuthFailure({ error: err.message }))
        throw err
    }
}

/**
 * Sign the user out of the application
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* signOutWorker() {
    const isImpersonating: boolean = yield select(
        selectAuthDomainValue,
        'userIsImpersonating'
    )

    try {
        if (isImpersonating) {
            // Reset impersonation
            yield put(setUserIsImpersonating(false))
        }

        yield put(signOutSuccess())

        // Remove local storage tokens
        // and stops third party tracking
        yield all([
            call(deleteCerebroAuthTokensSaga),
            call(unsetDebugUserContextSaga),
            call(stopChurnZero),
            call(clearSegmentTraits),
        ])

        // only redirect to auth page if we are not already there
        if (history.location.pathname !== getPath(AUTH_PAGE)) {
            history.push(getPath(AUTH_PAGE), {
                from: history.location,
            })
        }
    } catch (err: any) {
        yield put(signOutFailure({ error: err.message }))
    }
}
/**
 * Sign the user into the application
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* signInWorker(
    action: Action<{ email: string; password: string }>
) {
    const { email, password } = action.payload

    try {
        // Authorize with Cerebro
        yield call(authorizeCerebroSaga, email, password)

        yield call(setCognitoAuthTokensSaga, email, password)

        // Try to finish the sign-in process if we have an authorized user
        yield call(tryFinishSignInSaga)
        yield call(sendTrackEventSaga, 'Login - Login Success')
    } catch (err: any) {
        if (err.name === 'SmsMfaChallengeError') {
            yield call(sendTrackEventSaga, 'Login - MFA Challenge')
            yield put(signInMfaRequired())
            history.push({
                pathname: getPath(AUTH_SIGN_IN_MFA_PAGE),
                state: {
                    email: err.email,
                    password: err.password,
                    tokens: err.tokens,
                },
            })
            return
        }
        const userNotConfirmed: boolean = yield call(
            checkUserNotConfirmed,
            email
        )
        if (userNotConfirmed) {
            yield call(sendTrackEventSaga, 'Login - User Not Confirmed')
            yield call(resendSignUpCode, email)
            yield put(resendSignUpCodeSuccess({ email, password }))
            history.push(getPath(AUTH_VERIFY_EMAIL_PAGE))
        } else {
            yield call(sendTrackEventSaga, 'Login - Login Error')
            yield put(signInFailure({ error: err.message }))
        }
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* signInMfaWorker(
    action: Action<{
        email: string
        password: string
        tokens: Tokens
        code: string
    }>
) {
    const { email, password, tokens, code } = action.payload

    try {
        yield call(authorizeCerebroMfaSaga, email, password, tokens, code)

        yield call(tryFinishSignInSaga)
        yield call(sendTrackEventSaga, 'MFA - Login Success')
    } catch (err: any) {
        if (err.name === 'MfaSessionExpiredError') {
            yield call(sendTrackEventSaga, 'MFA - Login Session Expired')
            history.push(getPath(AUTH_PAGE))
        }
        yield call(sendTrackEventSaga, 'MFA - Login Error')
        yield put(signInFailure({ error: err.message }))
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* resendCodeWorker(
    action: Action<{ email: string; password: string }>
) {
    const { email, password } = action.payload

    try {
        // Authorize with Cerebro
        yield call(authorizeCerebroSaga, email, password)

        // Try to finish the sign-in process if we have an authorized user
        yield call(tryFinishSignInSaga)
    } catch (err: any) {
        if (err.name === 'SmsMfaChallengeError') {
            yield put(signInMfaRequired())
            yield put(resendCodeSuccess())
            generateCodeSentNotification(
                err.tokens?.ChallengeParameters?.CODE_DELIVERY_DESTINATION?.replace(
                    '+',
                    ''
                )
            )
            history.push({
                pathname: getPath(AUTH_SIGN_IN_MFA_PAGE),
                state: {
                    email: err.email,
                    password: err.password,
                    tokens: err.tokens,
                },
            })
        } else {
            yield put(signInFailure({ error: err.message }))
        }
    }
}

/**
 * Sign the user up
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* signUpWorker(action: Action<SignupData>) {
    const formValues = action.payload
    const { email, password, token } = formValues
    const signUpData = formatSignUpData(formValues)

    try {
        yield call(signUp, signUpData)
        yield put(signUpSuccess({ email, password }))
        yield call(sendTrackEventSaga, 'Signup - Signup Success')
        if (token) {
            yield put(
                signInRequest({
                    email,
                    password,
                })
            )
        } else {
            history.push(getPath(AUTH_VERIFY_EMAIL_PAGE))
        }
    } catch (err: any) {
        yield call(captureException, err)
        yield call(sendTrackEventSaga, 'Signup - Signup Error')
        yield put(signUpFailure({ error: err.message }))
    }
}

/**
 * Send a password reset email to the user
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* sendResetEmailWorker(action: Action<{ email: string }>) {
    try {
        const { email } = action.payload

        yield call(sendResetEmail, email)

        yield put(sendResetEmailSuccess())

        history.push(getPath(AUTH_RESET_PASSWORD_PAGE))
    } catch (err: any) {
        yield put(sendResetEmailFailure({ error: err.message }))
    }
}

/**
 * Reset a user's password
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* resetPasswordWorker(
    action: Action<{ email: string; code: string; newPassword: string }>
) {
    const { email, code, newPassword } = action.payload

    try {
        yield call(resetPassword, email, code, newPassword)

        // Trigger sign in
        yield put(
            signInRequest({
                email,
                password: newPassword,
            })
        )
    } catch (err: any) {
        yield put(resetPasswordFailure({ error: err.message }))
    }
}

/**
 * Change the user's organization
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* changeOrganizationWorker(
    action: Action<{
        organizationId: string
        redirect: string
        tab: string
        resources: any
    }>
) {
    const { organizationId, redirect, resources, tab } = action.payload

    try {
        const userSettings: UserSettings = yield call(fetchUserSettingsSaga)

        // Batch operations to change organization
        const [{ data: user }, { data: organization }]: [
            { data: User },
            { data: Organization },
        ] = yield all([
            call(getCurrentUser),
            call(getOrganization, organizationId),
            call(fetchUserOrganizationFeaturePermissionsSaga, {
                organizationId,
            }),
            call(updateUserSettingsSaga, [
                { path: ['organizationId'], value: organizationId },
            ]),
        ])

        // Re-register current user in third party services using the new organization
        yield all([
            call(registerPendoUser, user, organization),
            call(
                registerChurnZero,
                organization.salesforce_id,
                userSettings.gilId
            ),
            call(
                sendSegmentTrackAndIdentifyEvents,
                'Org changed',
                {},
                userSettings.gilId ?? '',
                user.id,
                getIdentifyProps(
                    user,
                    userSettings.gilId,
                    organization.salesforce_id
                )
            ),
        ])

        /// Redirect to the redirect url param or to homepage and reload
        let pathToRedirect
        if (redirect) {
            try {
                pathToRedirect = generatePath(getPath(redirect), resources)
                pathToRedirect = tab
                    ? `${pathToRedirect}?tab=${tab}`
                    : pathToRedirect
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error generating path', e)
                pathToRedirect = undefined
            }
        }

        history.replace(pathToRedirect ?? getPath(HOME_PAGE))
        yield put(mountAppRequest())
    } catch (err: any) {
        yield call(captureException, err)
        yield put(changeOrganizationFailure({ error: err.message }))
    }
}

/**
 * Change the user's organization group for impersonation mode
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* changeOrganizationGroupWorker(
    action: Action<{
        organizationId: string
        organizationGroupId: string
        redirect: string
        tab: string
        resources: any
    }>
) {
    const { organizationId, organizationGroupId, redirect, resources, tab } =
        action.payload

    try {
        // Batch operations to change organization group
        const [userSettings, { data: user }, { data: organization }]: [
            UserSettings,
            { data: User },
            { data: Organization },
        ] = yield all([
            call(fetchUserSettingsSaga),
            call(getCurrentUser),
            call(getOrganization, organizationId),
            call(fetchUserOrganizationFeaturePermissionsSaga, {
                organizationGroupId,
            }),
            call(fetchOrganizationsSaga),
            call(fetchOrganizationGroupsSaga, organizationId),
        ])

        // Re-register current user in third party services using the new organization
        yield all([
            call(registerPendoUser, user, organization),
            call(
                registerChurnZero,
                organization.salesforce_id,
                userSettings.gilId
            ),
            call(
                sendSegmentTrackAndIdentifyEvents,
                'Org group changed',
                {},
                userSettings.gilId ?? '',
                user.id,
                getIdentifyProps(
                    user,
                    userSettings.gilId,
                    organization.salesforce_id
                )
            ),
        ])

        /// Redirect to the redirect url param or to homepage and reload
        let pathToRedirect
        if (redirect) {
            try {
                pathToRedirect = generatePath(getPath(redirect), resources)
                pathToRedirect = tab
                    ? `${pathToRedirect}?tab=${tab}`
                    : pathToRedirect
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error generating path', e)
                pathToRedirect = undefined
            }
        }

        history.replace(pathToRedirect ?? getPath(HOME_PAGE))
        yield put(mountAppRequest())
    } catch (err: any) {
        yield call(captureException, err)
        yield put(changeOrganizationGroupFailure({ error: err.message }))
    }
}

/**
 * Assign a user to an organization
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* setActiveOrganizationSaga(organizationId: string) {
    // Set feature permissions in Redux store
    yield call(fetchUserOrganizationFeaturePermissionsSaga, {
        organizationId,
    })

    yield call(updateUserSettingsSaga, [
        { path: ['organizationId'], value: organizationId },
    ])

    // Set active organization id in Redux
    yield put(setOrganizationId({ organizationId }))
}

/**
 * Create an organization from the sign-up wizard
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* createAuthPageOrganizationWorker(
    action: Action<Organization>
) {
    try {
        const { payload } = action
        const { data: organization }: AxiosResponse<Organization> = yield call(
            cerebroApiSaga,
            null,
            createOrganization,
            payload
        )

        yield call(setActiveOrganizationSaga, organization.id)
        yield put(createAuthPageOrganizationSuccess(organization))
    } catch (err: any) {
        yield put(createAuthPageOrganizationFailure({ error: err.message }))
    }
}

/**
 * Confirm sign up, verifies email address with Cognito and
 * signs the user in if the confirm sign up request is successful
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* verifyEmailWorker(action: Action<{ code: string }>) {
    try {
        const email: string = yield select(selectAuthDomainValue, 'email')
        const password: string = yield select(selectAuthDomainValue, 'password')
        const { code } = action.payload

        yield call(confirmSignUp, email, code)

        // Trigger sign in
        yield put(
            signInRequest({
                email,
                password,
            })
        )
        yield call(sendTrackEventSaga, 'Signup - Verify Email Success')
    } catch (err: any) {
        yield put(verifyEmailFailure({ error: err.message }))
        yield call(sendTrackEventSaga, 'Signup - Verify Email Error')
    }
}

/**
 * Fetch the invitation by token from the invited user sign up form
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* getSignUpFormInvitationWorker(
    action: Action<{ token: string }>
) {
    try {
        const { token } = action.payload
        yield call(
            cerebroApiSaga,
            getSignUpFormInvitationSuccess,
            getInvitationByToken,
            token
        )
    } catch (err: any) {
        yield put(getSignUpFormInvitationFailure({ error: err.message }))
    }
}

/**
 * Accept an invitation from the invited user sign up form
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* acceptSignUpFormInvitationWorker(
    action: Action<{ invitationId: string }>
) {
    try {
        const { invitationId } = action.payload
        const organizationId: string = yield select(selectAuthDomainValue, [
            'invitation',
            'organization',
            'id',
        ])

        // Accept the invitation
        yield call(cerebroApiSaga, null, patchInvitation, invitationId, {
            state: 'accepted',
        })

        // Load the organization into redux store
        yield call(fetchOrganizationsSaga)

        yield call(setActiveOrganizationSaga, organizationId)

        yield put(acceptSignUpFormInvitationSuccess())
    } catch (err: any) {
        yield put(acceptSignUpFormInvitationFailure({ error: err.message }))
    }
}

/**
 * Accept an invitation from the organization menu
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* acceptInvitationWorker(
    action: Action<{ invitationId: string; callback?: () => void }>
) {
    try {
        const { invitationId, callback = noop } = action.payload
        const email: string = yield select(selectAuthDomainValue, 'email')

        // Accept the invitation
        yield call(cerebroApiSaga, null, patchInvitation, invitationId, {
            state: 'accepted',
        })

        // Refresh organizations and pending invites
        yield all([
            call(fetchOrganizationsSaga),
            call(fetchPendingInvitationsSaga, email),
        ])

        yield put(acceptInvitationSuccess())

        callback()
    } catch (err: any) {
        yield put(acceptInvitationFailure({ error: err.message }))
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchProfileFeaturePermissionsWorker(
    action: Action<{ profileId: string }>
) {
    const { profileId } = action.payload

    const permissions: ProfilePermission[] = yield select(
        selectProfileFeaturePermissions,
        profileId
    )

    if (isEmpty(permissions)) {
        const result: AxiosResponse<ProfilePermission[]> = yield call(
            cerebroApiSaga,
            null,
            getProfileFeaturePermissions,
            profileId
        )

        yield put(
            fetchProfileFeaturePermissionsSuccess({
                profileId,
                permissions: result.data,
            })
        )
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* fetchUserOrganizationFeaturePermissionsWorker(
    action: Action<{ organizationId: string }>
) {
    const { organizationId } = action.payload
    yield call(fetchUserOrganizationFeaturePermissionsSaga, { organizationId })
}

/**
 * Worker used when the current user starts impersonation mode
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* impersonationModeWorker() {
    try {
        const response: AxiosResponse<User> | null =
            yield call(fetchCurrentUserSaga)

        if (response === null || response.status !== 200) {
            const result: object = yield put(loadAuthFinish({}))
            return result
        }

        const { data: currentUser } = response
        const userSettingsTask: Task<UserSettings> = yield fork(
            fetchUserSettingsSaga
        )
        yield spawn(fetchOrganizationsSaga)

        const isImpersonating: boolean = yield select(
            selectAuthDomainValue,
            'userIsImpersonating'
        )

        const { organization, organizationGroup, userSettings } = yield call(
            getActiveOrganizationAndGroupSaga,
            isImpersonating,
            userSettingsTask
        )

        // Save user
        yield all([
            call(fetchOrganizationGroupsSaga, organization.id),
            call(fetchUserOrganizationFeaturePermissionsSaga, {
                organizationId: organization.id,
                organizationGroupId: organizationGroup?.id,
            }),
            call(saveCurrentUserToAppSaga, {
                currentUser,
                organization,
                organizationGroup,
                gilId: userSettings.gilId,
            }),
        ])

        // Redirect to the homepage
        history.push(getPath(HOME_PAGE))

        const result: object = yield put(loadAuthFinish({}))
        return result
    } catch (err: any) {
        yield call(captureException, err)
        yield put(impersonationModeFailure({ error: err.message }))
        throw err
    }
}
