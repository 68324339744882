import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

const SvgSuccessCenterIcon = (props: Props): ReactElement => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.33338 2.64998C5.03551 2.64998 3.98338 3.70211 3.98338 4.99998C3.98338 6.29784 5.03551 7.34998 6.33338 7.34998C7.63125 7.34998 8.68338 6.29784 8.68338 4.99998C8.68338 3.70211 7.63125 2.64998 6.33338 2.64998ZM2.68338 4.99998C2.68338 2.98414 4.31754 1.34998 6.33338 1.34998C8.34922 1.34998 9.98338 2.98414 9.98338 4.99998C9.98338 7.01581 8.34922 8.64998 6.33338 8.64998C4.31754 8.64998 2.68338 7.01581 2.68338 4.99998ZM10.0846 2.02254C10.2444 1.70107 10.6345 1.56997 10.956 1.72973C12.1562 2.32621 12.9834 3.5659 12.9834 4.99998C12.9834 6.43405 12.1562 7.67374 10.956 8.27022C10.6345 8.42998 10.2444 8.29888 10.0846 7.97741C9.92487 7.65593 10.056 7.26581 10.3774 7.10605C11.1528 6.72073 11.6834 5.92172 11.6834 4.99998C11.6834 4.07823 11.1528 3.27922 10.3774 2.8939C10.056 2.73414 9.92487 2.34402 10.0846 2.02254ZM6.33338 11.3166C4.63466 11.3166 3.04771 12.2035 1.84449 13.7349C1.6227 14.0172 1.21408 14.0662 0.9318 13.8444C0.649521 13.6226 0.60048 13.214 0.822265 12.9317C2.21437 11.1599 4.151 10.0166 6.33338 10.0166C8.51576 10.0166 10.4524 11.1599 11.8445 12.9317C12.0663 13.214 12.0172 13.6226 11.735 13.8444C11.4527 14.0662 11.0441 14.0172 10.8223 13.7349C9.61904 12.2035 8.03209 11.3166 6.33338 11.3166ZM11.4079 10.9096C11.5559 10.5825 11.941 10.4374 12.268 10.5854C13.3839 11.0903 14.3711 11.9049 15.1778 12.9317C15.3996 13.214 15.3506 13.6226 15.0683 13.8444C14.786 14.0662 14.3774 14.0172 14.1556 13.7349C13.4591 12.8483 12.6315 12.1768 11.7321 11.7698C11.405 11.6218 11.2599 11.2367 11.4079 10.9096Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgSuccessCenterIcon
