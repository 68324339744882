import { memo, ReactElement, ReactNode } from 'react'

import { Tooltip } from 'antd'
import { Field } from 'formik'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { FormikInput } from 'components/formik'
import { WarningIcon } from 'components/Icons'
import { IntegrationType } from 'const/integrations'
import { useOrgContext } from 'context/OrgContext'
import useCerebroApiRequest from 'hooks/useCerebroApiRequest'
import { useIntegrationIssuesInfo } from 'hooks/useIntegrationIssuesInfo'
import {
    updateIntegration,
    updateSellerCentralIntegration,
    updateVCIntegration,
} from 'services/cerebroApi/orgScope/resourceApi'
import {
    updateWalmartAdvertisers,
    updateWmMarketplaceIntegration,
} from 'services/cerebroApi/orgScope/walmartApi'
import { CerebroResponse, Integration, SellingPartnerIntegration } from 'types'
import message from 'utilities/message'

import { SingleValueField } from '../SharedFields'

interface Props<RecordType> {
    record: RecordType
    value: string
    reloadData: () => void
    integrationKey: IntegrationType
}

const MAP_INTEGRATION_KEY_TO_UPDATE_API: Record<
    IntegrationType,
    (integrationId: string, data: any) => any
> = {
    amazon_advertising: updateIntegration,
    selling_partner: updateSellerCentralIntegration,
    vendor_central: updateVCIntegration,
    walmart_marketplace: updateWmMarketplaceIntegration,
    walmart_advertising: updateWalmartAdvertisers,
}

function IntegrationAliasField<
    RecordType extends Integration | SellingPartnerIntegration,
>({
    record,
    value,
    reloadData,
    integrationKey,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['table'])
    const integrationIssuesInfo = useIntegrationIssuesInfo()
    const orgContext = useOrgContext()
    const integrationFirstIssue =
        orgContext?.orgAccountStatus?.integration_issues?.find(
            (issue) =>
                issue.integration_id === record.id &&
                issue.integration_alias === record.alias
        )
    const makeCerebroApiRequest = useCerebroApiRequest()
    const integrationId = record.id

    const fieldName = 'description'
    const validationSchema = object().shape({
        [fieldName]: string()
            .required()
            .label(t('table:fields.integrationDescription.name', 'Name')),
    })

    let mainIssue: ReactNode = null
    if (record.active && 'has_access' in record && !record.has_access) {
        mainIssue = (
            <Tooltip
                title={t(
                    'table:fields.integrationDescription.scLostAccess',
                    'This integration has lost access. Please reauthorize it.'
                )}
                placement="right"
            >
                <span>
                    <WarningIcon level="danger" />
                </span>
            </Tooltip>
        )
    } else if (
        record.active &&
        'seller_central_account' in record &&
        record.seller_central_account.integration_type === 'seller_central' &&
        moment('2023-03-01').isAfter(record.created_at) &&
        !record.authentication_updated_at
    ) {
        mainIssue = (
            <Tooltip
                title={t(
                    'account:IntegrationIssues.sc_sales_and_traffic.permissions.requires_reintegration.shortDescription',
                    'Reauthorize this integration to gain access to SC Sales & Traffic data'
                )}
            >
                <span>
                    <WarningIcon />
                </span>
            </Tooltip>
        )
    } else if (integrationFirstIssue) {
        mainIssue = (
            <Tooltip
                title={
                    (
                        integrationIssuesInfo[
                            integrationFirstIssue.issue_key
                        ] ?? integrationIssuesInfo.default
                    ).shortDescription
                }
            >
                <span>
                    <WarningIcon />
                </span>
            </Tooltip>
        )
    }

    return (
        <>
            <SingleValueField
                fieldName={fieldName}
                initialValues={{
                    [fieldName]: value,
                }}
                onSave={(values) => {
                    const updateRequestApi =
                        MAP_INTEGRATION_KEY_TO_UPDATE_API[integrationKey]
                    return makeCerebroApiRequest<
                        RecordType,
                        CerebroResponse<RecordType>
                    >({
                        request: updateRequestApi(`${integrationId}`, {
                            alias: values[fieldName],
                        }),
                        onRequestSuccess: () => {
                            message.success(
                                t(
                                    'table:fields.integrationDescription.updateSuccess',
                                    'Successfully updated integration name'
                                )
                            )
                            reloadData()
                        },
                    })
                }}
                validationSchema={validationSchema}
                formatFieldValue={(values) => values[fieldName]}
            >
                {({ isSubmitting, errors }) => (
                    <div>
                        <Field
                            component={FormikInput}
                            placeholder={t(
                                'table:fields.integrationDescription.name',
                                'Name'
                            )}
                            name={fieldName}
                            disabled={isSubmitting}
                        />
                        {errors[fieldName] && (
                            <div className="fg-control-text is-error">
                                {errors[fieldName]}
                            </div>
                        )}
                    </div>
                )}
            </SingleValueField>
            {mainIssue}
        </>
    )
}

export default memo(
    IntegrationAliasField,
    isEqual
) as typeof IntegrationAliasField
