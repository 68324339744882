import find from 'lodash/find'
import fromPairs from 'lodash/fromPairs'
import isUndefined from 'lodash/isUndefined'
import { createSelector } from 'reselect'

import { AUTOMATION_KEYS } from 'const/automations'
import { CAMPAIGN_PAGE } from 'const/pages'

import { selectDomainValue } from './ui'

const selectCapabilities = (state) =>
    selectDomainValue(state, [CAMPAIGN_PAGE, 'automation', 'capabilities'])

export const selectTasks = (state) =>
    selectDomainValue(state, [CAMPAIGN_PAGE, 'automation', 'tasks'])

export const selectAutomationProfiles = (state) =>
    selectDomainValue(state, [
        CAMPAIGN_PAGE,
        'automation',
        'automationProfiles',
        'automationProfiles',
    ])

/**
 * Returns map by capability Id of capability and task
 */
export const selectCapabilityTaskMapById = createSelector(
    selectCapabilities,
    selectTasks,
    (capabilities, tasks) =>
        fromPairs(
            capabilities.map((capability) => [
                capability.id,
                {
                    capability,
                    task: tasks.find((t) => t.capability?.id === capability.id),
                },
            ])
        )
)

/**
 * Checks automation tasks for current campaign to see if Dayparting is enabled
 */
export const selectIsDaypartingEnabled = createSelector(selectTasks, (tasks) =>
    tasks.some((task) => {
        const {
            capability: { id: capabilityType },
            enabled,
        } = task
        return capabilityType === AUTOMATION_KEYS.time_parting && enabled
    })
)

/**
 * Checks automation tasks for current campaign to see if Dayparting is enabled
 */
export const selectEffectiveDaypartingMultipliers = createSelector(
    selectTasks,
    (tasks) => {
        const daypartingTask = find(tasks, (task) => {
            const capabilityType = task.capability?.id
            return capabilityType === AUTOMATION_KEYS.time_parting
        })
        if (isUndefined(daypartingTask)) {
            return {}
        }

        const biddingProfile = daypartingTask.automation_profile
        return {
            hourly_multiplier: biddingProfile?.params?.hourly_multiplier,
            daily_multiplier: biddingProfile?.params?.daily_multiplier,
        }
    }
)
