import {
    removeLineItemFromLabelRenderer,
    removeOrderFromLabelRenderer,
} from 'components/FieldRenderers'
import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function advertiserId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['advertiser', 'id']
    return createField({
        ...options,
        id: 'advertiser_id',
        name: 'Advertiser',
        shortName: 'Advertiser',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function orderId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order', 'id']
    return createField({
        ...options,
        id: 'order_id',
        name: 'Order',
        shortName: 'Order',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function orderBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order', 'budget']
    return createField({
        ...options,
        id: 'order__budget',
        name: 'Order Budget',
        shortName: 'Order Budget',
        minWidth: 100,
        dataIndex,
        width: 200,
        align: 'right',
    })
}

export function orderLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order__label_id']
    return createField({
        ...options,
        id: 'order__label_id',
        name: 'Order Label',
        shortName: 'Order Label',
        minWidth: 50,
        dataIndex,
        width: 110,
    })
}

export function lineItemId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'id']
    return createField({
        ...options,
        id: 'line_item_id',
        name: 'Line Item',
        shortName: 'Line Item',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function lineItemType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'line_item_type']
    return createField({
        ...options,
        id: 'line_item__line_item_type',
        name: 'Line Item Type',
        shortName: 'Line Item Type',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function lineItemBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'budget']
    return createField({
        ...options,
        id: 'line_item__budget',
        name: 'Line Item Budget',
        shortName: 'Line Item Budget',
        minWidth: 100,
        dataIndex,
        align: 'right',
        width: 200,
    })
}

export function lineItemBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'bid']
    return createField({
        ...options,
        id: 'line_item__bid',
        name: 'Line Item Bid',
        shortName: 'Line Item Bid',
        minWidth: 100,
        dataIndex,
        width: 200,
        align: 'right',
    })
}

export function lineItemLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item__label_id']
    return createField({
        ...options,
        id: 'line_item__label_id',
        name: 'Line Item Label',
        shortName: 'Line Item Label',
        minWidth: 50,
        dataIndex,
        width: 110,
    })
}

export function removeLineItemFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_line_item_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeLineItemFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function removeOrderFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_order_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeOrderFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
