export const BRAND_COLORS = {
    PRIMARY: '#5561F6',
    SECONDARY: '#4083ff',
}

export const DELTA_COLORS = {
    GREEN: '#3bc274',
    RED: '#e75353',
}

export const COLOR_PICKER_OPTIONS = [
    '#a82e26',
    '#7c1e4d',
    '#431e86',
    '#2b218c',
    '#182579',
    '#1b489b',
    '#205796',
    '#265e63',
    '#1f4c41',
    '#c23e37',
    '#b32f5c',
    '#702b9c',
    '#4b33a2',
    '#31419a',
    '#3475cb',
    '#3788cb',
    '#4195a4',
    '#33766a',
    '#e25241',
    '#d73965',
    '#8f35a9',
    '#5f40b0',
    '#4154af',
    '#4596ec',
    '#48a8ee',
    '#4daec4',
    '#419286',
    '#274b35',
    '#406729',
    '#756c25',
    '#bd9315',
    '#cb8414',
    '#d65b26',
    '#b04121',
    '#3b2824',
    '#283137',
    '#518c46',
    '#759d47',
    '#949635',
    '#c8b21d',
    '#e49813',
    '#e37f1d',
    '#d5552d',
    '#584138',
    '#495963',
    '#67ab5b',
    '#97c15b',
    '#a8b142',
    '#e1cd32',
    '#ecb01e',
    '#f59631',
    '#ed6338',
    '#74574a',
    '#667c89',
]

export const DEFAULT_COLOR_PICKER_PROPS = {
    colors: COLOR_PICKER_OPTIONS,
    circleSize: 18,
    circleSpacing: 8,
}

export const SEGMENT_STATUS = {
    paused: '#C6C9CC',
    active: '#43C798',
    pausedBackground: '#F8F8F8',
    activeBackground: '#F5FAF8',
}
