import merge from 'deepmerge'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import union from 'lodash/union'
import uniqWith from 'lodash/uniqWith'
import xorBy from 'lodash/xorBy'
import { renderToString } from 'react-dom/server'

import { ALL_IDS } from 'const/reducerKeys'

/**
 * Customizer function used in conjuction with lodash mergeWith
 * to handle the use case of merging two arrays.
 *
 * Lodash merge by default always replaces objValue with srcValue,
 * but we have a special case in our entities ALL_IDS property
 * where we want the union of the two arrays returned from merge.
 *
 * @param {*} objValue The destination value
 * @param {*} srcValue The source value
 * @param {*} key The key of the values being merged
 */
export const mergeArray = (objValue, srcValue, key) => {
    if (isArray(objValue)) {
        if (key === ALL_IDS) {
            return union(objValue, srcValue)
        }
        return srcValue
    }

    return undefined
}

/**
 * Checks if value is unset, that is if null or undefined
 *
 * @param {any} value
 *
 * @returns {bool}
 */
export const isUnset = (value) => isUndefined(value) || isNull(value)

/**
 * Compare 2 arrays by iteratee
 *
 * @param {any[]} values1 Array
 * @param {any[]} values2 Array
 * @param {Function|string} iteratee Function to be used to compare elements
 *
 * @returns {bool} - TRUE if 2 arrays are the same
 */
export const isArrayEqualBy = (values1, values2, iteratee) =>
    isEmpty(xorBy(values1, values2, iteratee))

export const getCurrentTimestamp = () => new Date().getTime()

/**
 * Returns the user's OS
 * @see adapted from https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 *
 * @returns {string} OS
 */
export const getOS = () => {
    const { userAgent, platform } = window.navigator
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac OS'
    }
    if (iosPlatforms.indexOf(platform) !== -1) {
        return 'iOS'
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows'
    }
    if (/Android/.test(userAgent)) {
        return 'Android'
    }
    if (/Linux/.test(platform)) {
        return 'Linux'
    }

    return null
}

export const addCache = (func) => {
    func.cache = true
}

export const elementToString = (maybeElement) =>
    typeof maybeElement === 'string'
        ? maybeElement
        : renderToString(maybeElement)

export const mergeObjects = (objectsArray, ignoredKeys = []) => {
    const mergedObject = merge.all(objectsArray)

    const dedupedObject = {}
    Object.keys(mergedObject).forEach((key) => {
        if (
            isArray(mergedObject[key]) &&
            mergedObject[key].length > 1 &&
            !ignoredKeys.includes(key)
        ) {
            dedupedObject[key] = uniqWith(mergedObject[key], isEqual)
        } else {
            dedupedObject[key] = mergedObject[key]
        }
    })

    return dedupedObject
}

export const getNonEmptyKeyCount = (key, objectCollection) =>
    objectCollection.reduce(
        (count, obj) => (isEmpty(obj[key]) ? count : count + 1),
        0
    )
