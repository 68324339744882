import { AdGroup } from './adGroup'
import { Brand } from './brand'
import { Campaign } from './campaign'
import { AmsFact, SuggestedBid } from '../dataSources'

type NegativeKeywordStates = 'enabled' | 'paused' | 'deleted'

export interface NegativeKeyword {
    id: string
    amazon_resource_id: string
    text: string
    match_type: 'negativePhrase' | 'negativeExact'
    state: NegativeKeywordStates
    campaign: Campaign
    campaign_id: string
    ad_group: AdGroup
    ad_group_id: string
    created_date: string
    updated_date: string
    serving_status: string | null
    user_can_manage: boolean
}

export interface Keyword {
    ad_group: AdGroup
    ad_group_id: string
    amazon_resource_id: string
    active_bid: number
    base_bid: number
    bid: number
    base_state: 'enabled' | 'paused' | 'archived' | null
    campaign: Campaign
    campaign_id: string
    created_date: string
    id: string
    match_type: 'exact' | 'broad' | 'phrase'
    profile: Brand
    serving_status: null
    state: 'enabled' | 'paused' | 'archived'
    text: string
    updated_date: string | null
    user_can_manage: boolean
    user_can_manage_detail: string | null
    suggestedBid?: SuggestedBid
}

export interface KeywordGroupBy {
    keyword: Keyword
    keyword_id: string
    results_count?: number
}

export enum AdsPermissionStatus {
    ENABLED = 'enabled',
    NO_AMAZON_AD_PERMISSION = 'noAmazonAdPermission',
    NO_AD_INTEGRATION_FOR_COUNTRY = 'noAdIntegrationForCountry',
    GENERIC_ERROR = 'genericError',
}

// Including AMS Facts
export type KeywordFact<T = {}> = KeywordGroupBy & AmsFact & T
