import {
    targetExpressionRenderer,
    targetSuggestedBidRenderer,
    targetStateRenderer,
    archiveTargetRenderer,
    expressionDescriptionRenderer,
} from 'components/FieldRenderers/Renderers/target'
import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function targetExpressions<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'expressions']
    return createField({
        ...options,
        id: 'target_expressions',
        name: 'Target',
        shortName: 'Target',
        minWidth: 70,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => targetExpressionRenderer<any>(props),
        },
        width: 200,
        sorter: options.sorter ?? false,
    })
}

export function expressionDescription<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    // preserving the expression_description key here for backwards compatibility
    // so we don't break the column set requested for exports
    const dataIndex = options.dataIndex ?? ['target', 'expression_description']
    return createField({
        ...options,
        id: 'expression_description',
        name: 'Target Description',
        shortName: 'Target',
        minWidth: 70,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => expressionDescriptionRenderer<any>(props),
        },
        width: 200,
        sorter: options.sorter ?? false,
    })
}

export function targetAmazonResourceId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'amazon_resource_id']
    return createField({
        ...options,
        id: 'target_amazon_resource_id',
        name: 'Amazon Resource ID',
        shortName: 'Amazon Resource ID',
        minWidth: 80,
        dataIndex,
        width: 100,
        sorter: options.sorter ?? false,
    })
}

export function targetSuggestedBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'suggestedBid']
    return createField({
        ...options,
        id: 'target_suggested_bid',
        name: 'Suggested Bid',
        shortName: 'Suggested Bid',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                targetSuggestedBidRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 150,
        sorter: options.sorter ?? false,
    })
}

export function targetBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'base_bid']
    return createField({
        ...options,
        id: 'target_bid',
        name: 'Bid',
        shortName: 'Bid',
        minWidth: 70,
        dataIndex,
        width: 100,
        sorter: options.sorter ?? false,
    })
}

export function targetActiveBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'active_bid']
    return createField({
        ...options,
        id: 'target_active_bid',
        name: 'Active Bid',
        shortName: 'Active Bid',
        minWidth: 70,
        dataIndex,
        width: 100,
        sorter: options.sorter ?? false,
    })
}

export function targetState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target', 'state']
    return createField({
        ...options,
        id: 'target_state',
        name: 'Target State',
        shortName: 'State',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                targetStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 100,
        sorter: options.sorter ?? false,
    })
}

export function targetResolvedExpression<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['resolved_expression']
    return createField({
        ...options,
        id: 'resolved_expression',
        name: 'Target Expression',
        shortName: 'Target Expression',
        isResizeable: true,
        minWidth: 70,
        isVisible: true,
        dataIndex,
        width: 200,
    })
}

export function archiveTarget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['target']
    return createActionField({
        ...options,
        id: 'archive_target',
        isResizeable: false,
        minWidth: 68,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                archiveTargetRenderer<any>({ cellRenderProps, dataIndex }),
        },
        sorter: false,
        fixed: options.fixed ?? 'right',
        align: 'center',
    })
}
