import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import SuccessCenterIconSvg from './SuccessCenterIconSvg'
import { IconProps } from '../localTypes'

const SuccessCenterIcon = (props: IconProps): ReactElement => (
    <Icon component={SuccessCenterIconSvg} {...props} />
)

export default SuccessCenterIcon
