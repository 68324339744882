import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import flatMap from 'lodash/fp/flatMap'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import get from 'lodash/get'
import has from 'lodash/has'
import map from 'lodash/map'

import {
    applySuggestedBidFailure,
    applySuggestedBidRequest,
    applySuggestedBidSuccess,
    fetchSBBidRecommendationsSuccess,
    fetchSDBidRecommendationsSuccess,
    fetchSPBidRecommendationsSuccess,
} from 'actions/ui/shared/bid'
import { HEADLINE_SEARCH } from 'const/factTypes'

export default {
    [fetchSBBidRecommendationsSuccess](state, action) {
        const { path, data: bidRecommendations } = action.payload
        return flow(
            set([...path, 'loading'], false),
            update([...path, 'data'], (data) =>
                map(data, (row) => {
                    // If the row contains a "target" key use this for bid recommendation rather than keyword
                    const key = has(row, ['target']) ? 'target' : 'keyword'

                    const recommendation = bidRecommendations.find(
                        (recc) => recc.resourceId === row[`${key}_id`]
                    )

                    if (recommendation) {
                        return set([key, 'suggestedBid'], {
                            rangeEnd: recommendation.bidRecommendation.rangeEnd,
                            rangeStart:
                                recommendation.bidRecommendation.rangeStart,
                            suggested:
                                recommendation.bidRecommendation.recommended,
                        })(row)
                    }
                    return row
                })
            )
        )(state)
    },
    [fetchSDBidRecommendationsSuccess](state, action) {
        const { path, data: bidRecommendations } = action.payload
        return flow(
            set([...path, 'loading'], false),
            update([...path, 'data'], (data) =>
                map(data, (row) => {
                    const recommendation = bidRecommendations.find(
                        (recc) => recc.targetId === row.target_id
                    )
                    if (recommendation?.bidRecommendation.code === '200') {
                        return set(['target', 'suggestedBid'], {
                            rangeEnd:
                                recommendation.bidRecommendation.rangeUpper,
                            rangeStart:
                                recommendation.bidRecommendation.rangeLower,
                            suggested:
                                recommendation.bidRecommendation.recommended,
                        })(row)
                    }
                    return row
                })
            )
        )(state)
    },

    [fetchSPBidRecommendationsSuccess](state, action) {
        const { path, data: bidRecommendations } = action.payload

        const filterByAdGroup = (adGroupId) => [
            'adGroupId',
            parseInt(adGroupId.replace(/\D/g, ''), 10),
        ]

        return flow(
            set([...path, 'loading'], false),
            update([...path, 'data'], (data) =>
                map(data, (row) => {
                    // If the row contains a "target" key use this for bid recommendation rather than keyword
                    const key =
                        has(row, ['target']) && !has(row, ['keyword'])
                            ? 'target'
                            : 'keyword'

                    const recommendation = {
                        keyword: {
                            adGroupId: get(row, ['keyword', 'ad_group_id']),
                            find: (rec) => {
                                const { type, value } = get(rec, [
                                    'expression',
                                    '0',
                                ])
                                return (
                                    get(row, ['keyword', 'text']) === value &&
                                    get(row, ['keyword', 'match_type']) ===
                                        type
                                            .replace('query', '')
                                            .replace('Matches', '')
                                            .toLowerCase()
                                )
                            },
                        },
                        target: {
                            adGroupId: get(row, ['target', 'ad_group', 'id']),
                            find: (rec) => {
                                const { type, value } = get(rec, [
                                    'expression',
                                    '0',
                                ])

                                return (
                                    get(row, [
                                        'target',
                                        'expressions',
                                        '0',
                                        'value',
                                    ]) === value &&
                                    get(row, [
                                        'target',
                                        'expressions',
                                        '0',
                                        'type',
                                    ]) === type
                                )
                            },
                        },
                    }

                    const recc = flow(
                        filter(filterByAdGroup(recommendation[key].adGroupId)),
                        flatMap((chunk) => chunk.recommendations),
                        filter((rec) => rec.code === 'SUCCESS'),
                        find(recommendation[key].find)
                    )(bidRecommendations)

                    if (row[key].campaign.campaign_type === HEADLINE_SEARCH) {
                        return row
                    }

                    if (recc) {
                        return set(
                            [key, 'suggestedBid'],
                            recc.suggestedBid
                        )(row)
                    }

                    return row
                })
            )
        )(state)
    },

    [applySuggestedBidRequest](state, action) {
        const {
            path,
            data: { keywordId },
        } = action.payload

        return set([...path, 'applyingSuggestedBidId'], keywordId)(state)
    },

    [applySuggestedBidSuccess](state, action) {
        const { path, data: keyword } = action.payload

        return flow(
            set([...path, 'applyingSuggestedBidId'], null),
            update([...path, 'data'], (data) =>
                map(data, (row) => {
                    if (row.keyword.id === keyword.id) {
                        keyword.suggestedBid = row.keyword.suggestedBid
                        return set(['keyword'], keyword)(row)
                    }
                    return row
                })
            )
        )(state)
    },

    [applySuggestedBidFailure](state, action) {
        const { path, data } = action.payload

        return flow(
            set([...path, 'applyingSuggestedBidId'], null),
            set([...path, 'error'], data)
        )(state)
    },
}
