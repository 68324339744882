import { captureException } from '@sentry/browser'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import {
    all,
    call,
    delay,
    put,
    race,
    select,
    spawn,
    take,
} from 'redux-saga/effects'

import {
    createBrandGroupSuccess,
    deleteBrandGroupSuccess,
    dismissUserSurveySuccess,
    fetchAutomationCapabilitiesSuccess,
    fetchBrandGroupsSuccess,
    fetchCurrencySettingsSuccess,
    fetchCustomEventsSuccess,
    fetchHiddenAutomationCapabilities,
    fetchHomeDashboardIdSuccess,
    fetchOrganizationLabelsSuccess,
    fetchReportsForDrawerFailure,
    fetchReportsForDrawerSuccess,
    fetchSovPermissionsSuccess,
    fetchTriggeredAlertsForDrawerFailure,
    fetchTriggeredAlertsForDrawerSuccess,
    fetchUpdatesForDrawerFailure,
    fetchUpdatesForDrawerSuccess,
    fetchUserSurveyLastReadDateSuccess,
    mountAppFailure,
    mountAppSuccess,
    unmountAppDrawer,
    updateBrandGroupSuccess,
} from 'actions/ui/app'
import {
    makeFetchPageDataRequest,
    makeFetchTabDataRequest,
    makeUpdateTablePagination,
} from 'actions/ui/shared'
import {
    BACK_OFF_RATE,
    POLL_INTERVAL,
    POLL_INTERVAL_MAX,
    POLL_TIMEOUT,
} from 'const/polling'
import { isSomeUpdatesPending } from 'helpers/bulkUpdate'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import { getCurrentPage, getCurrentTab } from 'helpers/pages'
import {
    fetchLatestActiveOrganizationIntegrationSaga,
    fetchLatestOrganizationAdIntegrationsSaga,
    fetchLatestWalmartAdvertiserSaga,
    fetchUserSettingsSaga,
    updateUserSettingsSaga,
} from 'sagas/auth/workers'
import { cerebroApiSaga } from 'sagas/common'
import {
    selectDomainValue as selectAuthDomainValue,
    selectOrgKey,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import {
    selectCurrencyCode,
    selectDomainValue as selectUiDomainValue,
} from 'selectors/ui'
import { getTriggeredAlerts } from 'services/cerebroApi/orgScope/alertsApi'
import { getCustomEvents } from 'services/cerebroApi/orgScope/customEventsApi'
import { getDownloads } from 'services/cerebroApi/orgScope/downloadsApi'
import {
    createBrandGroup,
    deleteBrandGroup,
    getAutomationCapabilities,
    getBrandGroups,
    getLabels,
    getSovPermissions,
    updateBrandGroup,
} from 'services/cerebroApi/orgScope/resourceApi'
import { getAsyncUpdates } from 'services/cerebroApi/orgScope/updatesApi'
import message from 'utilities/message'
import numeral from 'utilities/numeral'

export function* setFeedbackSurveyLastReadDateRequestWorker(action) {
    try {
        const lastReadDate = action.payload
        yield all([
            call(updateUserSettingsSaga, [
                { path: ['feedbackSurveyLastRead'], value: lastReadDate },
            ]),
            put(dismissUserSurveySuccess(lastReadDate)),
        ])
    } catch (error) {
        yield call(captureException, error)
    }
}

function* fetchSurveyLastReadSaga(lastReadDate) {
    try {
        yield put(fetchUserSurveyLastReadDateSuccess(lastReadDate))
    } catch (error) {
        yield call(captureException, error)
    }
}

function* fetchTriggeredAlertsSaga() {
    try {
        const triggeredAlertsResponse = yield call(getTriggeredAlerts, {
            ordering: '-triggered_at',
            limit: 15,
            am_subscribed: true,
            subscription_type: 'in-app',
        })

        yield put(
            fetchTriggeredAlertsForDrawerSuccess(triggeredAlertsResponse.data)
        )
    } catch (error) {
        yield call(captureException, error)
    }
}

function* setDisplayCurrencySaga(currencyCode) {
    yield call([numeral, 'locale'], currencyCode)
}

function* fetchCurrencySettingsSaga(currency) {
    const userId = yield select(selectAuthDomainValue, ['username'])

    if (!userId) {
        return
    }

    const orgKey = yield select(selectOrgKey)
    const currencyCode = get(currency, [orgKey], null)

    yield put(fetchCurrencySettingsSuccess(currencyCode))
    yield call(setDisplayCurrencySaga, currencyCode)
}

function* fetchReportsForDrawerSaga() {
    return yield call(
        cerebroApiSaga,
        fetchReportsForDrawerSuccess,
        getDownloads,
        {
            ordering: '-created_at',
            limit: 15,
        }
    )
}

function* fetchUpdatesForDrawerSaga() {
    return yield call(
        cerebroApiSaga,
        fetchUpdatesForDrawerSuccess,
        getAsyncUpdates,
        {
            ordering: '-created_date',
            limit: 15,
        }
    )
}

function* fetchTriggeredAlertsForDrawerSaga() {
    return yield call(
        cerebroApiSaga,
        fetchTriggeredAlertsForDrawerSuccess,
        getTriggeredAlerts,
        {
            ordering: '-triggered_at',
            limit: 15,
            am_subscribed: true,
            subscription_type: 'in-app',
        }
    )
}

function* resetPaginationSaga(path) {
    const tablePath = [...path, 'table']

    if (yield select(selectUiDomainValue, tablePath)) {
        yield put(makeUpdateTablePagination(tablePath)({ current: 1 }))
    }
}

function* resetCurrentPaginationSaga() {
    const { page: pageName } = getCurrentPage()

    if (pageName) {
        yield call(resetPaginationSaga, [pageName])

        const tabName = getCurrentTab()
        if (tabName) {
            yield call(resetPaginationSaga, [pageName, camelCase(tabName)])
        }
    }
}

function* fetchCurrentDataSaga() {
    const { page: pageName } = getCurrentPage()

    if (pageName) {
        yield put(makeFetchPageDataRequest(pageName)())

        const tabName = getCurrentTab()

        if (tabName) {
            yield put(makeFetchTabDataRequest([pageName, camelCase(tabName)])())
        }
    }
}

export function* reloadCurrentDataSaga() {
    // Reset Pagination
    yield call(resetCurrentPaginationSaga)

    // Reload data
    yield call(fetchCurrentDataSaga)
}

const isUnmountPageAction = ({ type }) => {
    const unmountAction = unmountAppDrawer()
    return type === unmountAction.type
}

function* checkUploadStatusSaga(apiRequestAction, apiFailAction) {
    let delayInterval = POLL_INTERVAL
    while (true) {
        try {
            const response = yield call(apiRequestAction)
            if (response && !isSomeUpdatesPending(response.data.results)) {
                break
            }
        } catch (error) {
            yield put(apiFailAction(error))
            break
        }
        yield delay(Math.min(delayInterval, POLL_INTERVAL_MAX))
        delayInterval += delayInterval * BACK_OFF_RATE
    }
}

function* startPollingSaga(apiRequestAction, apiFailAction) {
    const response = yield call(apiRequestAction)

    // start polling if there are pending reports
    let hasTimedOut = false
    if (
        response?.data?.results &&
        isSomeUpdatesPending(response.data.results)
    ) {
        const { timedOut } = yield race({
            response: call(
                checkUploadStatusSaga,
                apiRequestAction,
                apiFailAction
            ),
            timedOut: delay(POLL_TIMEOUT),
            unmount: take(isUnmountPageAction),
        })
        hasTimedOut = timedOut
    }

    if (hasTimedOut) {
        const error = {
            message:
                'This drawer has stopped checking for updates. Re-open the drawer to start checking for updates again.',
        }

        yield call(captureException, error)
        const closeMessage = message.warning(error.message, 0)

        // remove the message whenever the user closes the drawer
        yield take(isUnmountPageAction)
        closeMessage()
    }
}

/**
 * Polls for report status for Reports Drawer
 */
export function* fetchReportsForDrawerRequestWorker() {
    // use spawn to start polling in 'detached' fork mode
    // see https://redux-saga.js.org/docs/advanced/ForkModel.html
    yield spawn(
        startPollingSaga,
        fetchReportsForDrawerSaga,
        fetchReportsForDrawerFailure
    )
}

/**
 * Polls for update status for Updates Drawer
 */
export function* fetchUpdatesForDrawerRequestWorker() {
    // use spawn to start polling in 'detached' fork mode
    // see https://redux-saga.js.org/docs/advanced/ForkModel.html
    yield spawn(
        startPollingSaga,
        fetchUpdatesForDrawerSaga,
        fetchUpdatesForDrawerFailure
    )
}

/**
 * Fetch Triggered Alerts for Drawer
 */
export function* fetchTriggeredAlertsForDrawerRequestWorker() {
    try {
        yield call(fetchTriggeredAlertsForDrawerSaga)
    } catch (error) {
        yield all([
            call(captureException, error),
            put(fetchTriggeredAlertsForDrawerFailure(error)),
        ])
    }
}

/**
 * Fetch all labels for an organization
 */
export function* fetchOrganizationLabelsSaga() {
    yield call(cerebroApiSaga, fetchOrganizationLabelsSuccess, getLabels, {
        limit: 'null', // fetch all available labels when loading app1
    })
}

function* fetchHiddenAutomationCapabilitiesSaga(hiddenAutomationCapabilities) {
    const userId = yield select(selectAuthDomainValue, ['username'])

    if (!userId) {
        return
    }

    const featurePermissions = yield select(
        selectUserOrganizationFeaturePermissions
    )

    if (!userHasCustomerServicePermissions(featurePermissions)) {
        return
    }

    yield put(fetchHiddenAutomationCapabilities(hiddenAutomationCapabilities))
}

/**
 * Set which automation capabilities are hidden in the application
 *
 * Used when demoing to a sensitive audience in "Steve Mode"
 */
export function* setHiddenAutomationCapabilitiesWorker(action) {
    const automationCapabilities = action.payload
    yield call(updateUserSettingsSaga, [
        {
            path: ['hiddenAutomationCapabilities'],
            value: automationCapabilities,
        },
    ])
}

/**
 * Fetch all automation capabilities
 */
function* fetchAutomationCapabilitiesSaga() {
    yield call(
        cerebroApiSaga,
        fetchAutomationCapabilitiesSuccess,
        getAutomationCapabilities
    )
}

/**
 * Fetch all custom events
 */
export function* fetchCustomEventsSaga() {
    yield call(
        cerebroApiSaga,
        fetchCustomEventsSuccess,
        getCustomEvents,
        null,
        {
            headers: { noCount: true },
        }
    )
}

/**
 * Fetch all brand groups
 */
function* fetchBrandGroupsSaga() {
    yield call(cerebroApiSaga, fetchBrandGroupsSuccess, getBrandGroups, null, {
        headers: { noCount: true },
    })
}

export function* createBrandGroupWorker(action) {
    const { data, callback } = action.payload

    const response = yield call(
        cerebroApiSaga,
        createBrandGroupSuccess,
        createBrandGroup,
        data
    )

    if (callback) {
        callback(response.data)
    }
}

export function* updateBrandGroupWorker(action) {
    const { brandGroupId, data, callback } = action.payload

    const response = yield call(
        cerebroApiSaga,
        updateBrandGroupSuccess,
        updateBrandGroup,
        brandGroupId,
        data
    )

    if (callback) {
        callback(response.data)
    }
}

export function* deleteBrandGroupWorker(action) {
    const { brandGroupId, callback } = action.payload

    yield call(cerebroApiSaga, null, deleteBrandGroup, brandGroupId)
    yield put(deleteBrandGroupSuccess(brandGroupId))

    if (callback) {
        callback()
    }
}

/**
 * Changes default currency code for the user
 */
export function* changeCurrencyCodeWorker() {
    const orgKey = yield select(selectOrgKey)
    const currencyCode = yield select(selectCurrencyCode)

    yield call(setDisplayCurrencySaga, currencyCode)
    yield all([
        call(fetchCurrentDataSaga),
        call(updateUserSettingsSaga, [
            { path: ['currency', orgKey], value: currencyCode },
        ]),
    ])
}

function* fetchHomeDashboardIdSaga(dashboard) {
    const orgKey = yield select(selectOrgKey)
    const dashboardId = get(dashboard, [orgKey], null)
    yield put(fetchHomeDashboardIdSuccess(dashboardId))
}

export function* setHomeDashboardIdWorker(action) {
    const orgKey = yield select(selectOrgKey)
    const { dashboardId } = action.payload
    yield call(updateUserSettingsSaga, [
        { path: ['homeDashboardId', orgKey], value: dashboardId },
    ])
}

function* fetchAllSettingsSaga() {
    const userSettings = yield call(fetchUserSettingsSaga)

    yield call(fetchSurveyLastReadSaga, userSettings.feedbackSurveyLastRead)
    yield call(
        fetchHiddenAutomationCapabilitiesSaga,
        userSettings.hiddenAutomationCapabilities
    )
    yield call(fetchCurrencySettingsSaga, userSettings.currency)
    yield call(fetchHomeDashboardIdSaga, userSettings.homeDashboardId)
}

function* fetchSovPermissionsSaga() {
    yield call(cerebroApiSaga, fetchSovPermissionsSuccess, getSovPermissions)
}

/**
 * Mounts the application
 */
export function* mountAppWorker() {
    try {
        yield all([
            // Fetch global user settings
            call(fetchAllSettingsSaga),

            // Load global data from Cerebro
            call(fetchAutomationCapabilitiesSaga),
            call(fetchOrganizationLabelsSaga),
            call(fetchBrandGroupsSaga),
            call(fetchCustomEventsSaga),
            call(fetchLatestOrganizationAdIntegrationsSaga),
            call(fetchLatestActiveOrganizationIntegrationSaga),
            call(fetchLatestWalmartAdvertiserSaga),
            call(fetchTriggeredAlertsSaga),
            call(fetchSovPermissionsSaga),
        ])

        yield put(mountAppSuccess())
    } catch (error) {
        yield call(captureException, error)
        yield put(mountAppFailure(error))
    }
}
