import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import sum from 'lodash/sum'
import pluralize from 'pluralize'

import {
    AND_SUFFIX,
    INSENSITIVE_STARTS_WITH,
    INSENSITIVE_ENDS_WITH,
    DOES_NOT_CONTAIN,
    CONTAINS,
    INSENSITIVE_EXACT,
    STARTS_WITH,
    ENDS_WITH,
    EXACT,
} from 'const/filters'

export const hasAllOptionsSelected = (
    selectedOptions,
    allOptions,
    key = 'value'
) => isEqual(orderBy(selectedOptions, [key]), orderBy(allOptions, [key]))

const resolveFilterValue = (value) => {
    return typeof value === 'number' ? value : value.join()
}

const ops = [
    INSENSITIVE_STARTS_WITH,
    INSENSITIVE_ENDS_WITH,
    DOES_NOT_CONTAIN,
    CONTAINS,
    INSENSITIVE_EXACT,
    STARTS_WITH,
    ENDS_WITH,
    EXACT,
]
function extractOpFromKey(key) {
    for (let i = 0; i < ops.length; i += 1) {
        const op = ops[i]
        if (key.endsWith(op)) {
            return op
        }
    }
    return undefined
}
export function decompileBooleanFilter(filter) {
    const result = {
        and: filter.and.map((outerItem) => ({
            or: outerItem.or.map((innerItem) => {
                const key = Object.keys(innerItem)[0]
                const val = innerItem[key]
                const operator = extractOpFromKey(key)
                const resourceType = key.replace(operator, '')
                return {
                    operator,
                    resourceType,
                    value: [val],
                }
            }),
        })),
    }
    return [
        {
            value: JSON.stringify(result),
        },
    ]
}

export function buildBooleanFilter(filters, defaultResourceType) {
    const filter = filters[0]

    // Backwards compatibility with old format
    if (filter.label) {
        return {
            [`${defaultResourceType}${AND_SUFFIX}`]: filters
                .map((f) => f.value)
                .join(),
        }
    }
    const filterVal = JSON.parse(filter.value)
    return {
        and: filterVal.and.map((outerItem) => ({
            or: outerItem.or.map((innerItem) => ({
                [`${innerItem.resourceType}${innerItem.operator}`]:
                    resolveFilterValue(innerItem.value),
            })),
        })),
    }
}

export const addToComplexTextFilter = (currentTextFilter, newPart) => {
    const newFilter = { and: [] }
    if (currentTextFilter?.and) {
        newFilter.and = [...currentTextFilter.and]
    }
    const newBooleanFilter = buildBooleanFilter(newPart)
    newFilter.and.push(...newBooleanFilter.and)
    return newFilter
}

export const getBooleanFilterLabel = (
    currentValue,
    labelFormatter,
    resourceName,
    entityText = 'Rule',
    useEntityTextOnSingleRule = false
) => {
    if (!currentValue) {
        return resourceName
    }
    if (
        currentValue.and?.length === 1 &&
        currentValue.and[0].or?.length === 1
    ) {
        if (labelFormatter) {
            const {
                operator,
                resourceType,
                value: labelValue,
            } = currentValue.and[0].or[0]

            return labelFormatter(resourceType, operator, labelValue)
        }

        const count = sum(
            (currentValue.and || []).flatMap((and) =>
                (and.or || []).flatMap((leaf) =>
                    isArray(leaf.value) ? leaf.value.length : leaf.value
                )
            )
        )
        return `${pluralize(resourceName, count)}: ${count} ${
            useEntityTextOnSingleRule ? pluralize(entityText, count) : ''
        }`
    }

    const ruleCount = sum(
        (currentValue.and || []).flatMap((and) => (and.or || []).length)
    )

    return `${pluralize(resourceName, ruleCount)}: ${ruleCount} ${pluralize(
        entityText,
        ruleCount
    )}`
}

export function formatBooleanFilter(filters, defaultResourceType) {
    return JSON.stringify(buildBooleanFilter(filters, defaultResourceType))
}

/**
 * Transforms dates filter from cerebro to use in Forge
 *
 * @param {String} filterValue "last_week" or "2019-08-01,2019-09-01"
 */
export function serializeDatesFilter(filterValue) {
    const dates = get(filterValue, ['0', 'value'], '')
    const datesArray = dates.split(',')
    return datesArray.length === 1 ? datesArray[0] : datesArray
}

/**
 * Formats dates filter to be saved in cerebro
 *
 * @param {String|Array} filterValue "last_week" or [2019-08-01,2019-09-01"]
 */
export function deserializeDatesFilter(filterValue) {
    if (isArray(filterValue) && !isEmpty(filterValue)) {
        return [{ value: filterValue.join() }]
    }
    return [{ value: filterValue }]
}
