import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import {
    DATES,
    BRANDS,
    DSP_ADVERTISERS,
    DSP_ORDERS,
    DSP_LINE_ITEMS,
    LABELS,
} from 'const/filters'
import { DSP_ORDER_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'
import { isNonEmptyArray } from '../typeGuard'

export const formatFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[filters[DATES]]

            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[BRANDS])) {
        params.profile_id__in = filters[BRANDS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[DSP_ADVERTISERS])) {
        params.advertiser_id__in = filters[DSP_ADVERTISERS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_ORDERS])) {
        params.order_id__in = filters[DSP_ORDERS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_LINE_ITEMS])) {
        params.line_item_id__in = filters[DSP_LINE_ITEMS].map(
            (item) => item.value
        ).join()
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(
            labels,
            DSP_ORDER_LABELS
        )
    }

    return params
}
