import { Translation } from 'react-i18next'

import {
    automationProfileIdRenderer,
    automationStateRenderer,
    automationDetachProfileRenderer,
    automationDeleteProfileRenderer,
} from 'components/FieldRenderers/Renderers/automation'
import { Field } from 'types'

import {
    createActionField,
    createCerebroDateField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function automationCapabilityId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['capability', 'id']
    const name = (
        <Translation>
            {(t) => t('table:fields.automationType.name', 'Automation Type')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'automation_capability_id',
        name,
        shortName: name,
        dataIndex,
        sorter: options.sorter ?? false,
    })
}

export function automationState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['enabled']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.automationState.name', 'Automation Enabled')
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'automation_state',
        name,
        shortName: name,
        dataIndex,
        sorter: options.sorter ?? false,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                automationStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
    })
}

export function automationDetachProfile<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'automation_detach_profile',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        width: 68,
        sorter: false,
        fixed: 'right',
        align: 'center',
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                automationDetachProfileRenderer<any>({ cellRenderProps }),
        },
    })
}

export function automationDeleteProfile<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'automation_delete_profile',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        width: 68,
        sorter: false,
        fixed: 'right',
        align: 'center',
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                automationDeleteProfileRenderer<any>({ cellRenderProps }),
        },
    })
}

export function automationStartDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['start_date']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.automationStartDate.name',
                    'Activity Start Date'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) =>
                t('table:fields.automationStartDate.shortName', 'Start Date')
            }
        </Translation>
    )
    return createCerebroDateField({
        ...options,
        id: 'automation_start_date',
        name,
        shortName,
        dataIndex,
        minWidth: 80,
        columnTitle: name,
    })
}

export function automationEndDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['end_date']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.automationEndDate.name', 'Activity End Date')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.automationEndDate.shortName', 'End Date')}
        </Translation>
    )
    return createCerebroDateField({
        ...options,
        id: 'automation_end_date',
        name,
        shortName,
        dataIndex,
        minWidth: 80,
        columnTitle: name,
        isVisible: false,
    })
}

export function automationProfileName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>
            {(t) => t('table:fields.automationProfile.name', 'Profile Name')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'automation_profile_name',
        name,
        shortName: name,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => automationProfileIdRenderer<any>(props),
        },
        minWidth: 100,
        width: 300,
    })
}

export function automationProfileTaskCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['automation_task_count']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.automationProfileTaskCount.name',
                    'Associated Campaigns'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'automation_profile_task_count',
        name,
        shortName: name,
        dataIndex,
        minWidth: 50,
        width: 80,
    })
}

export function automationProfileCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_at']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.automationProfileCreatedDate.name',
                    'Created Date'
                )
            }
        </Translation>
    )
    return createCerebroDateField({
        ...options,
        id: 'automation_profile_created_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
    })
}

export function automationProfileHasOverrides<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['has_overrides']
    const isVisible = options.isVisible ?? true
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.automationProfileHasOverrides.name',
                    'Has Overrides'
                )
            }
        </Translation>
    )
    const title = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.automationProfileHasOverrides.title',
                    'Has Overrides?'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'automation_profile_has_overrides',
        name,
        shortName: name,
        minWidth: 80,
        isVisible,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => {
                if (value === true) {
                    return (
                        <span style={{ color: '#3bc274' }}>
                            <Translation>
                                {(t) => t('common:yes', 'Yes')}
                            </Translation>
                        </span>
                    )
                }
                return (
                    <span>
                        <Translation>{(t) => t('common:no', 'No')}</Translation>
                    </span>
                )
            },
        },
        sorter: options.sorter ?? false,
        columnTitle: title,
    })
}
