import {
    useState,
    useCallback,
    useEffect,
    ReactElement,
    CSSProperties,
} from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import compact from 'lodash/compact'
import { v4 as uuid } from 'uuid'

import { getOS } from 'helpers/utilities'

import { Item } from './localTypes'
import styles from './styles.scss'

const { TextArea } = Input

interface Props {
    errorMsg?: string
    label: string
    loading: boolean
    /** Pushes a list of items  */
    push: (item: Item) => void
    style?: CSSProperties
    type: string
    validator?: (items: string[]) => boolean
    className?: string
}

const BulkAddTextArea = ({
    errorMsg,
    label = 'Element',
    loading = false,
    push,
    style,
    type,
    validator = () => true,
    className = '',
}: Props): ReactElement => {
    const [inputValue, setInputValue] = useState('')
    const [items, setItems] = useState<string[]>([])
    const [isValid, setIsValid] = useState(true)

    const validatorFn = useCallback(validator, [])
    const isFieldEmpty = inputValue.length <= 0
    const enterKey = getOS() === 'Windows' ? 'Ctrl +' : '⌘ +'
    const isNullOrEmpty = (str: string): boolean => !str || !str.trim()

    useEffect(() => {
        const itemsToAdd = compact(inputValue.split('\n')).filter(
            (values) => !isNullOrEmpty(values)
        )

        setItems(() => {
            setIsValid(validatorFn(itemsToAdd))
            return itemsToAdd
        })
    }, [inputValue, validatorFn])

    const addItems = (): void => {
        if (isValid) {
            items.forEach((item, index) => {
                push({
                    [type]: item,
                    index,
                    id: uuid(),
                })
            })
            setInputValue('')
        }
    }

    return (
        <div
            className={`${styles['add-field-wrapper']} ${className}`}
            style={style}
        >
            <TextArea
                autoSize={{ minRows: 3, maxRows: 10 }}
                className={styles['add-field']}
                disabled={loading}
                onChange={(e) => {
                    setInputValue(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.metaKey && !isFieldEmpty) {
                        e.stopPropagation()
                        addItems()
                    }
                }}
                placeholder={`Add ${label} (line separated)`}
                value={inputValue}
            />
            {!isValid && errorMsg && (
                <div className="fg-control-text is-error">{errorMsg}</div>
            )}
            <div className={styles['add-field-affix']}>
                {!isFieldEmpty && isValid && (
                    <span className="fg-text-extra-light mr-2">
                        {enterKey}{' '}
                        <span className={styles['enter-icon']}>&#9166;</span> to
                        add
                    </span>
                )}
                <Button
                    size="small"
                    icon={<PlusOutlined />}
                    shape="circle"
                    type={!isFieldEmpty ? 'primary' : 'default'}
                    onClick={() => {
                        if (!isFieldEmpty) {
                            addItems()
                        }
                    }}
                    disabled={loading || !isValid}
                />
            </div>
        </div>
    )
}

export default BulkAddTextArea
