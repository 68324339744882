/**
 * Checks if a string is an Amazon ASIN
 * @see https://stackoverflow.com/a/12827734/3112403
 */
export const isASIN = (value: string): boolean =>
    /^(B\d{2}[A-Z\d]{7}|\d{9}[X\d])$/i.test(value)

/**
 * Formats an Amazon ASIN
 */
export const formatASIN = (value: string): string => value.toUpperCase()
