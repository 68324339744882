import { createAction } from 'redux-actions'

import { User } from 'types'

// Reset Auth
export const resetAuthErrors = createAction('RESET_AUTH_ERRORS')

// Load Auth

export interface LoadAuthFinishPayload {
    displayDowntimePage?: boolean
    displayMaintenancePage?: boolean
}

export const loadAuthRequest = createAction('LOAD_AUTH_REQUEST')
export const loadAuthFinish =
    createAction<LoadAuthFinishPayload>('LOAD_AUTH_FINISH')
export const loadAuthFailure = createAction('LOAD_AUTH_FAILURE')

// Fetch organization integration status
export const fetchLatestOrganizationIntegrationSuccess = createAction(
    'FETCH_LATEST_ORGANIZATION_INTEGRATION_SUCCESS'
)

// Fetch latest walmart advertiser integration
export const fetchLatestWalmartAdvertiserSuccess = createAction(
    'FETCH_LATEST_WALMART_ADVERTISER_INTEGRATION_SUCCESS'
)

export const fetchLatestOrganizationAdIntegrationsSuccess = createAction(
    'FETCH_LATEST_ORGANIZATION_AD_INTEGRATIONS_SUCCESS'
)

// Change organization
export const changeOrganizationRequest = createAction(
    'CHANGE_ORGANIZATION_REQUEST'
)
export const changeOrganizationFailure = createAction(
    'CHANGE_ORGANIZATION_FAILURE'
)
export const setOrganizationId = createAction('SET_ORGANIZATION_ID')

// Change organization group
export const changeOrganizationGroupRequest = createAction(
    'CHANGE_ORGANIZATION_GROUP_REQUEST'
)
export const changeOrganizationGroupFailure = createAction(
    'CHANGE_ORGANIZATION_GROUP_FAILURE'
)

// Fetch user settings
export const fetchUserSettingsSuccess = createAction(
    'FETCH_USER_SETTINGS_SUCCESS'
)

// Save user feature permissions
export const fetchUserFeaturePermissionsSuccess = createAction(
    'FETCH_USER_FEATURE_PERMISSIONS_SUCCESS'
)

// Change user organization feature permissions
export const fetchUserOrganizationFeaturePermissionsRequest = createAction(
    'FETCH_USER_ORGANIZATION_FEATURE_PERMISSIONS_REQUEST'
)
export const fetchUserOrganizationFeaturePermissionsSuccess = createAction(
    'FETCH_USER_ORGANIZATION_FEATURE_PERMISSIONS_SUCCESS'
)

// Sign Up
export const signUpRequest = createAction('SIGN_UP_REQUEST')
export const signUpSuccess = createAction('SIGN_UP_SUCCESS')
export const signUpFailure = createAction('SIGN_UP_FAILURE')

// Sign In
export const signInRequest = createAction('SIGN_IN_REQUEST')
export const signInMfaRequest = createAction('SIGN_IN_MFA_REQUEST')
export const signInMfaRequired = createAction('SIGN_IN_MFA_REQUIRED')

export const resendCodeRequest = createAction('RESEND_CODE_REQUEST')
export const resendCodeSuccess = createAction('RESEND_CODE_SUCCESS')

export interface SignInSuccessPayload {
    currentUser: User
    organizationId: string | null | undefined
    organizationGroupId: string | null | undefined
}

export const signInSuccess =
    createAction<SignInSuccessPayload>('SIGN_IN_SUCCESS')
export const signInFailure = createAction('SIGN_IN_FAILURE')

// Sign Out
export const signOutRequest = createAction('SIGN_OUT_REQUEST')
export const signOutSuccess = createAction('SIGN_OUT_SUCCESS')
export const signOutFailure = createAction('SIGN_OUT_FAILURE')

// Send reset email
export const sendResetEmailRequest = createAction('SEND_RESET_EMAIL_REQUEST')
export const sendResetEmailSuccess = createAction('SEND_RESET_EMAIL_SUCCESS')
export const sendResetEmailFailure = createAction('SEND_RESET_EMAIL_FAILURE')

// Reset password
export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST')
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE')

// Create Organization
export const createAuthPageOrganizationRequest = createAction(
    'CREATE_AUTH_PAGE_ORGANIZATION_REQUEST'
)
export const createAuthPageOrganizationSuccess = createAction(
    'CREATE_AUTH_PAGE_ORGANIZATION_SUCCESS'
)
export const createAuthPageOrganizationFailure = createAction(
    'CREATE_AUTH_PAGE_ORGANIZATION_FAILURE'
)

// Verify Email Address
export const verifyEmailRequest = createAction('VERIFY_EMAIL_REQUEST')
export const verifyEmailSuccess = createAction('VERIFY_EMAIL_SUCCESS')
export const verifyEmailFailure = createAction('VERIFY_EMAIL_FAILURE')

// Confirm Account
export const resendSignUpCodeSuccess = createAction(
    'RESEND_SIGN_UP_CODE_SUCCESS'
)

// Invitation
export const getSignUpFormInvitationRequest = createAction(
    'GET_SIGN_UP_FORM_INVITATION_REQUEST'
)
export const getSignUpFormInvitationSuccess = createAction(
    'GET_SIGN_UP_FORM_INVITATION_SUCCESS'
)
export const getSignUpFormInvitationFailure = createAction(
    'GET_SIGN_UP_FORM_INVITATION_FAILURE'
)

export const acceptSignUpFormInvitationRequest = createAction(
    'ACCEPT_SIGN_UP_FORM_INVITATION_REQUEST'
)
export const acceptSignUpFormInvitationSuccess = createAction(
    'ACCEPT_SIGN_UP_FORM_INVITATION_SUCCESS'
)
export const acceptSignUpFormInvitationFailure = createAction(
    'ACCEPT_SIGN_UP_FORM_INVITATION_FAILURE'
)

// profile feature permissions
export const fetchProfileFeaturePermissionsRequest = createAction(
    'FETCH_PROFILE_FEATURE_PERMISSIONS_REQUEST'
)
export const fetchProfileFeaturePermissionsSuccess = createAction(
    'FETCH_PROFILE_FEATURE_PERMISSIONS_SUCCESS'
)

// pending invitations
export const fetchPendingInvitationsSuccess = createAction(
    'FETCH_PENDING_INVITATIONS_SUCCESS'
)
export const acceptInvitationRequest = createAction('ACCEPT_INVITATION_REQUEST')
export const acceptInvitationSuccess = createAction('ACCEPT_INVITATION_SUCCESS')
export const acceptInvitationFailure = createAction('ACCEPT_INVITATION_FAILURE')

// Impersonation
export const setUserIsImpersonating = createAction('SET_USER_IS_IMPERSONATING')
export const impersonationModeFailure = createAction(
    'IMPERSONATION_MODE_FAILURE'
)
