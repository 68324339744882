import { useMemo } from 'react'

import {
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { DATA_SOURCES, PAGE_DASHBOARD_CONFIG } from 'configuration/widgets'
import { DATES, RANGE_LAG } from 'const/filters'
import { TABLE, TEXT, TIME_SERIES } from 'const/widgets'
import { useOrgContext } from 'context/OrgContext'
import { useUserOverrideContext } from 'context/UserOverrideContext'
import { getPriorPeriodDateRange } from 'helpers/dateRange'
import {
    formatCurrency,
    formatPeriodDeltaDateRange,
    formatSorter,
    formatStringMetrics,
} from 'helpers/params'
import {
    formatAutoFilters,
    formatGoals,
    formatWidgetGroupBy,
    getWidgetApiFunc,
    getWidgetMetrics,
} from 'helpers/ui/dashboardPage'
import { isWidgetHourlyAgg, zoomOut } from 'helpers/widgets'
import { useCerebroApiRequest } from 'hooks'
import {
    CerebroPaginatedResponse,
    CurrencyCode,
    DateRangeFilter,
    Widget,
    WidgetFilterMap,
} from 'types'

interface WidgetDataApi {
    getWidgetData: (
        pageName: string,
        widget: Widget,
        filters: WidgetFilterMap,
        currencyCode: CurrencyCode,
        noCount: boolean,
        pageNumber?: number,
        pageSize?: number
    ) => Promise<CerebroPaginatedResponse<any> | null>
    getWidgetPriorPeriodData: (
        pageName: string,
        widget: Widget,
        filters: WidgetFilterMap,
        currencyCode: CurrencyCode,
        noCount: boolean,
        pageNumber?: number,
        pageSize?: number
    ) => Promise<CerebroPaginatedResponse<any> | null>
}

function buildWidgetFilterParams(
    pageName: string,
    widget: Widget,
    filters: WidgetFilterMap,
    datePrefix: string,
    hasHourlyData: boolean
): any {
    const formatFiltersFunc =
        DATA_SOURCES[widget.data_source]?.formatFiltersFunc ?? (() => ({}))
    const { formatPageFiltersFunc } = PAGE_DASHBOARD_CONFIG[pageName]

    return {
        // temp hack, but this lookup typing is a bit messy
        ...(formatPageFiltersFunc as any)(filters, datePrefix, hasHourlyData),
        ...formatFiltersFunc(filters, hasHourlyData),
    }
}

function buildWidgetParams(
    widget: Widget,
    filterParams: any,
    filters: WidgetFilterMap,
    currencyCode: CurrencyCode,
    datePrefix: string,
    pageNumber?: number,
    pageSize?: number
): any {
    const dashboardCurrency: CurrencyCode = currencyCode
    const metrics = getWidgetMetrics(widget)

    const pagination =
        pageNumber && pageSize
            ? {
                  limit: pageSize,
                  offset: (pageNumber - 1) * pageSize,
              }
            : {}

    return {
        ...formatStringMetrics(metrics),
        ...filterParams,
        ...pagination,
        ...formatSorter(widget.sorter),
        ...formatCurrency(dashboardCurrency),
        ...formatWidgetGroupBy(widget, filters),
        ...formatAutoFilters(widget),
        ...(widget.type !== TIME_SERIES && !!widget.period_delta_date_range
            ? formatPeriodDeltaDateRange(
                  widget.include_change_metrics ?? false,
                  widget.period_delta_type,
                  widget.period_delta_date_range,
                  filters[DATES] as DateRangeFilter,
                  datePrefix,
                  filters[RANGE_LAG] as unknown as number
              )
            : {}),
        ...formatGoals(widget),
    }
}

function useWidgetDataApi(): WidgetDataApi {
    const makeCerebroApiRequest = useCerebroApiRequest({ throwOnError: true })
    const { userOverride } = useUserOverrideContext()

    return useMemo(
        () => ({
            getWidgetData: (
                pageName: string,
                widget: Widget,
                filters: WidgetFilterMap,
                currencyCode: CurrencyCode,
                noCount: boolean,
                pageNumber?: number,
                pageSize?: number
            ) => {
                if (widget.type === TEXT) {
                    return Promise.resolve(null)
                }
                const datePrefix =
                    DATA_SOURCES[widget.data_source]?.datePrefix ?? ''
                let hasHourlyData = isWidgetHourlyAgg(widget)
                const filterParams = buildWidgetFilterParams(
                    pageName,
                    widget,
                    filters,
                    datePrefix,
                    hasHourlyData
                )

                const options = {
                    headers: {
                        noCount: widget.type !== TABLE || noCount,
                        userId: userOverride?.userId,
                        orgId: userOverride?.organizationId,
                        orgGroupId: userOverride?.organizationGroupId,
                    },
                }

                const { widget: tempWidget, isZoomedOut } = zoomOut(widget, [
                    filterParams[`${datePrefix}_min`],
                    filterParams[`${datePrefix}_max`],
                ])

                if (isZoomedOut) {
                    hasHourlyData = false
                }

                const params = buildWidgetParams(
                    tempWidget,
                    filterParams,
                    filters,
                    currencyCode,
                    datePrefix,
                    pageNumber,
                    pageSize
                )

                const apiFunc = getWidgetApiFunc(widget)
                return makeCerebroApiRequest({
                    request: apiFunc(params, options),
                    suppressErrorAlerts: true,
                })
            },
            getWidgetPriorPeriodData: (
                pageName: string,
                widget: Widget,
                filters: WidgetFilterMap,
                currencyCode: CurrencyCode,
                noCount: boolean,
                pageNumber?: number,
                pageSize?: number
            ) => {
                if (widget.type === TEXT) {
                    return Promise.resolve(null)
                }
                const datePrefix =
                    DATA_SOURCES[widget.data_source]?.datePrefix ?? ''
                let hasHourlyData = isWidgetHourlyAgg(widget)
                const filterParams = buildWidgetFilterParams(
                    pageName,
                    widget,
                    filters,
                    datePrefix,
                    hasHourlyData
                )

                const options = {
                    headers: {
                        noCount: widget.type !== TABLE || noCount,
                        userId: userOverride?.userId,
                        orgId: userOverride?.organizationId,
                        orgGroupId: userOverride?.organizationGroupId,
                    },
                }

                const { widget: tempWidget, isZoomedOut } = zoomOut(widget, [
                    filterParams[`${datePrefix}_min`],
                    filterParams[`${datePrefix}_max`],
                ])

                if (isZoomedOut) {
                    hasHourlyData = false
                }

                const params = buildWidgetParams(
                    tempWidget,
                    filterParams,
                    filters,
                    currencyCode,
                    datePrefix,
                    pageNumber,
                    pageSize
                )
                const dateRange = getPriorPeriodDateRange(
                    params[`${datePrefix}_min`],
                    params[`${datePrefix}_max`],
                    tempWidget.period_delta_type,
                    filters[DATES] as DateRangeFilter,
                    hasHourlyData
                )

                const apiFunc = getWidgetApiFunc(widget)
                return makeCerebroApiRequest({
                    request: apiFunc(
                        {
                            ...params,
                            [`${datePrefix}_min`]: dateRange[0],
                            [`${datePrefix}_max`]: dateRange[1],
                        },
                        options
                    ),
                    suppressErrorAlerts: true,
                })
            },
        }),
        [
            makeCerebroApiRequest,
            userOverride?.organizationGroupId,
            userOverride?.organizationId,
            userOverride?.userId,
        ]
    )
}

export function useGetWidgetDataQuery(
    pageName: string,
    widget: Widget,
    filters: WidgetFilterMap,
    currencyCode: CurrencyCode,
    noCount: boolean,
    pageNumber?: number,
    pageSize?: number,
    options?: Exclude<
        UseQueryOptions<CerebroPaginatedResponse<any> | null, AxiosError>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<CerebroPaginatedResponse<any> | null, AxiosError> {
    const { getWidgetData } = useWidgetDataApi()
    const { orgId, orgGroupId } = useOrgContext()

    // pull out props that shouldn't trigger a reload
    const {
        pagination,
        layout,
        json,
        type,
        name,
        description,
        chart_type,
        show_filter_row,
        stacked,
        stacked_type,
        id, // not including widget id since it doesn't affect the data
        filters: _filters, // we're handling filters separately
        constant_value_for_prior_period: _constant_value_for_prior_period, // not including it since it doesn't affect the data
        ...keyWorthyProps
    } = widget

    const key = {
        ...keyWorthyProps,
        orgId,
        orgGroupId,
        group_by: widget.group_by.map((groupBy) =>
            groupBy && typeof groupBy === 'object' ? groupBy.id : groupBy
        ),
        metrics: widget.metrics.map((metric) =>
            metric && typeof metric === 'object' ? metric.id : metric
        ),
        metrics_by_axis: {
            left: widget.metrics_by_axis?.left?.map((metric) =>
                metric.metrics.map((m) => m.metric)
            ),
            right: widget.metrics_by_axis?.right?.map((metric) =>
                metric.metrics.map((m) => m.metric)
            ),
        },
    }

    return useQuery({
        queryKey: [
            'widget',
            pageName,
            currencyCode,
            noCount,
            key,
            filters,
            pageNumber,
            pageSize,
        ],
        ...options,
        queryFn: () =>
            getWidgetData(
                pageName,
                widget,
                filters,
                currencyCode,
                noCount,
                pageNumber,
                pageSize
            ),
    })
}

export function useGetWidgetPriorPeriodDataQuery(
    pageName: string,
    widget: Widget,
    filters: WidgetFilterMap,
    currencyCode: CurrencyCode,
    noCount: boolean,
    pageNumber?: number,
    pageSize?: number,
    options?: Exclude<
        UseQueryOptions<CerebroPaginatedResponse<any> | null, AxiosError>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<CerebroPaginatedResponse<any> | null, AxiosError> {
    const { getWidgetPriorPeriodData } = useWidgetDataApi()
    const { orgId } = useOrgContext()

    // pull out props that shouldn't trigger a reload
    const {
        pagination,
        layout,
        json,
        type,
        name,
        description,
        chart_type,
        show_filter_row,
        stacked,
        stacked_type,
        id, // not including widget id since it doesn't affect the data
        filters: _filters, // we're handling filters separately
        constant_value_for_prior_period: _constant_value_for_prior_period, // not including it since it doesn't affect the data
        ...keyWorthyProps
    } = widget

    const key = {
        ...keyWorthyProps,
        orgId,
        group_by: widget.group_by.map((groupBy) =>
            groupBy && typeof groupBy === 'object' ? groupBy.id : groupBy
        ),
        metrics: widget.metrics.map((metric) =>
            metric && typeof metric === 'object' ? metric.id : metric
        ),
        metrics_by_axis: {
            left: widget.metrics_by_axis?.left?.map((metric) =>
                metric.metrics.map((m) => m.metric)
            ),
            right: widget.metrics_by_axis?.right?.map((metric) =>
                metric.metrics.map((m) => m.metric)
            ),
        },
    }

    return useQuery({
        queryKey: [
            'widgetPriorPeriod',
            pageName,
            currencyCode,
            noCount,
            key,
            filters,
            pageNumber,
            pageSize,
        ],
        ...options,
        queryFn: () =>
            getWidgetPriorPeriodData(
                pageName,
                widget,
                filters,
                currencyCode,
                noCount,
                pageNumber,
                pageSize
            ),
    })
}
