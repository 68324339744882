import { ReactElement, ReactNode } from 'react'

import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, CountryCode, ProductMetadata } from 'types'

import { SegmentProductTitleCell } from '../SegmentProductTitleCell'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
}
interface SegmentProductInfo {
    product_id?: string
    asin: string
    name: string | null
    title: string | null
    marketplace?: CountryCode
    small_image_url?: string
    variant_count__max?: number
    product?: {
        title: string
        small_image_url?: string
    }
}

const extractMarketplaceFromRecord = (
    record: SegmentProductInfo
): CountryCode | undefined => {
    let { marketplace } = record
    if (!marketplace && record.product_id) {
        marketplace = record.product_id.split('_')[0] as CountryCode
    }
    return marketplace
}

export function SegmentProductNameRenderer<
    RecordType extends SegmentProductInfo,
>(props: RenderProps<RecordType>, pathToProduct: string[] = []): ReactElement {
    const { t } = useTranslation(['segments'])
    const {
        cellRenderProps: { record },
    } = props
    const product =
        !!record && pathToProduct.length ? get(record, pathToProduct) : record

    const ASIN = record?.asin ?? record?.product_id?.split('_')[1]

    if (isUnset(product)) {
        if ((record?.variant_count__max ?? 0) > 0) {
            return (
                <span>
                    {t(
                        'segments:fields.product.parentWithAsin',
                        'Parent ASIN ({{count}} variant): {{ASIN}}',
                        {
                            count: record.variant_count__max,
                            ASIN,
                        }
                    )}
                </span>
            )
        }
        return <span>{ASIN}</span>
    }

    return <div className="d-flex align-items-center">{product.title}</div>
}

export function TitleRenderer<RecordType extends SegmentProductInfo>({
    record,
    showImage,
}: CellRenderProps<RecordType>): ReactNode {
    const { product } = record
    return (
        <SegmentProductTitleCell
            value={product?.title ?? record.title ?? record.name}
            productImg={product?.small_image_url ?? record.small_image_url}
            productAsin={record.asin}
            productMarketplace={extractMarketplaceFromRecord(record)}
            showImage={showImage}
            numChildren={record.variant_count__max ?? 0}
        />
    )
}

export function CategoryListRenderer<RecordType extends ProductMetadata>({
    record,
    undefinedValue = <span>{UNDEFINED_VALUE}</span>,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(record?.sub_categories)) {
        return undefinedValue
    }
    return (
        <span>
            {record.sub_categories
                ?.map((sc) => {
                    return sc.name
                })
                .join(', ')}
        </span>
    )
}
