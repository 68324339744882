import {
    AddKeywordCell,
    searchTermQueryRenderer,
} from 'components/FieldRenderers'
import { TARGET_QUERY_SUPPORTED_METRICS_COLUMNS_PAGES } from 'configuration/dataSources/targetQuery'
import {
    addSearchTerm,
    adGroupName,
    keywordId,
    keywordMatchType,
    query,
    targetResolvedExpression,
    brandId,
    portfolioId,
    campaignId,
    campaignTargetingType,
} from 'configuration/fieldCreators'
import {
    AdGroupGroupBy,
    CampaignGroupBy,
    ProfileGroupBy,
    Field,
    QueryFact,
    KeywordGroupBy,
    ResolvedExpressionGroupBy,
    KeywordQueryFact,
    PortfolioGroupBy,
} from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export type CampaignQueryFactRecord = QueryFact<
    CampaignGroupBy &
        AdGroupGroupBy &
        KeywordGroupBy &
        ResolvedExpressionGroupBy
>

export const campaignKeywordSearchTermsTableColumnsConfig: Field<CampaignQueryFactRecord>[] =
    [
        query({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => searchTermQueryRenderer(props),
            },
        }),
        keywordId(),
        keywordMatchType(),
        adGroupName(),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignQueryFactRecord>[]),
        addSearchTerm({
            renderOptions: {
                render: ({ record }) => <AddKeywordCell record={record} />,
            },
        }),
    ]

export const campaignTargetSearchTermsTableColumnsConfig: Field<CampaignQueryFactRecord>[] =
    [
        query({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => searchTermQueryRenderer(props),
            },
        }),
        targetResolvedExpression(),
        adGroupName(),
        ...(TARGET_QUERY_SUPPORTED_METRICS_COLUMNS_PAGES as Field<CampaignQueryFactRecord>[]),
        addSearchTerm({
            renderOptions: {
                render: ({ record }) => <AddKeywordCell record={record} />,
            },
        }),
    ]

export type QueryFactRecord = QueryFact<
    ProfileGroupBy &
        PortfolioGroupBy &
        CampaignGroupBy &
        AdGroupGroupBy &
        KeywordGroupBy &
        ResolvedExpressionGroupBy
>

export const keywordQueryFactTableColumnsConfig: Field<KeywordQueryFact>[] = [
    query({ fixed: 'left', sorter: true }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<KeywordQueryFact>[]),
]

export const keywordSearchTermsSummaryTableColumnsConfig: Field<QueryFactRecord>[] =
    [
        query({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => searchTermQueryRenderer(props),
            },
        }),
        keywordId(),
        keywordMatchType(),
        brandId(),
        portfolioId(),
        campaignId(),
        campaignTargetingType(),
        adGroupName(),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<QueryFactRecord>[]),
        addSearchTerm({
            renderOptions: {
                render: ({ record }) => <AddKeywordCell record={record} />,
            },
        }),
    ]

export const productTargetSearchTermsSummaryTableColumnsConfig: Field<QueryFactRecord>[] =
    [
        query({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => searchTermQueryRenderer(props),
            },
        }),
        targetResolvedExpression(),
        brandId(),
        portfolioId(),
        campaignId(),
        campaignTargetingType(),
        adGroupName(),
        ...(TARGET_QUERY_SUPPORTED_METRICS_COLUMNS_PAGES as Field<QueryFactRecord>[]),
        addSearchTerm({
            renderOptions: {
                render: ({ record }) => <AddKeywordCell record={record} />,
            },
        }),
    ]
