import { presetRanges } from 'helpers/dateRange'
import { formatCerebroDate } from 'helpers/formatting'
import {
    isDefined,
    isPresetDateRange,
    isNonEmptyArray,
} from 'helpers/typeGuard'
import { DateRangeFilter } from 'types'

export const extractValue = (
    text: string,
    startAfter: string,
    stopBefore: string
): string => {
    if (!text.includes(startAfter)) {
        return ''
    }

    const startIndex = text.indexOf(startAfter) + startAfter.length

    let endIndex =
        stopBefore.length !== 0
            ? text.indexOf(stopBefore, startIndex)
            : text.length

    if (endIndex < 0) {
        endIndex = text.length
    }

    return text.slice(startIndex, endIndex)
}

export const formatDateFromFilter = (
    dates?: DateRangeFilter,
    fromDate?: string,
    lagInHours?: number
): { start?: string; end?: string } => {
    if (!isDefined(dates)) {
        return {}
    }

    let start
    let end

    if (isPresetDateRange(dates)) {
        const { range } = presetRanges(fromDate, lagInHours)[dates]
        start = range[0]
        end = range[1]
    } else if (isNonEmptyArray(dates)) {
        start = dates[0]
        end = dates[1]
    }

    return { start: formatCerebroDate(start), end: formatCerebroDate(end) }
}
