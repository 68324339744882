import pickBy from 'lodash/pickBy'
import createCachedSelector from 're-reselect'

import {
    AGGREGATION,
    SEGMENT_ASIN_EXCLUDES,
    SEGMENT_ASIN_INCLUDES,
    SEGMENT_CATEGORY,
    SEGMENT_PRICE_MIN_MAX,
    SEGMENT_REVENUE_MIN_MAX,
    SEGMENT_REVIEWS_MIN_MAX,
    SEGMENT_SALES_MIN_MAX,
} from 'const/filters'
import {
    APP,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    HOME_PAGE,
} from 'const/pages'
import { LOCAL_FILTERS, FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { mergeObjects } from 'helpers/utilities'
import { RootReduxState, FiltersState, FilterSettings } from 'types'

import { selectDomainValue } from './ui'

export const selectLocalFilters = (
    state: RootReduxState,
    path: string[]
): FiltersState => selectDomainValue(state, [...path, LOCAL_FILTERS]) ?? {}

export const selectFilters = <T extends FiltersState = FiltersState>(
    state: RootReduxState,
    path: string[]
): T => selectDomainValue(state, [...path, FILTERS]) ?? {}

export const selectFilterSettings = (
    state: RootReduxState,
    path: string[]
): FilterSettings => selectDomainValue(state, [...path, FILTER_SETTINGS]) ?? {}

export const selectVisibleFilters = createCachedSelector(
    selectFilters,
    selectFilterSettings,
    (filters, { anchored = [], displayState = {} }: FilterSettings) => {
        return pickBy(
            filters,
            (_value, key) => anchored.includes(key) || displayState[key]
        )
    }
)((_state, path) => JSON.stringify(path))

export const selectVisibleCombinedFilters = (
    state: RootReduxState,
    path: string[]
): FiltersState => {
    const pagePath = path.slice(0, 1)

    let appFilters = selectFilters(state, [APP])
    const page = pagePath[0]

    // DATES filter is stored at the dashboard level on DASHBOARD_PAGE
    // AGGREGATION filter is stored at the widget level on DASHBOARD_PAGE
    if (page === DASHBOARD_PAGE || page === DASHBOARD_PRINT_PAGE) {
        appFilters = {}
    }

    // AGGREGATION filter is stored at the widget level on HOME_PAGE
    if (page === HOME_PAGE) {
        appFilters = {
            ...appFilters,
            [AGGREGATION]: undefined,
        }
    }

    const pageLocalFilters = selectLocalFilters(state, pagePath)
    const pageFilters = selectVisibleFilters(state, pagePath)
    const tabLocalFilters = selectLocalFilters(state, path)
    const tabFilters = selectVisibleFilters(state, path)

    return mergeObjects(
        [
            appFilters,
            pageLocalFilters,
            pageFilters,
            tabLocalFilters,
            tabFilters,
        ],
        [
            'dates',
            SEGMENT_ASIN_INCLUDES,
            SEGMENT_ASIN_EXCLUDES,
            SEGMENT_PRICE_MIN_MAX,
            SEGMENT_REVENUE_MIN_MAX,
            SEGMENT_SALES_MIN_MAX,
            SEGMENT_REVIEWS_MIN_MAX,
            SEGMENT_CATEGORY,
        ]
    )
}
