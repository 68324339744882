import { upperCase } from 'lodash'
import compact from 'lodash/compact'
import chunk from 'lodash/fp/chunk'
import filter from 'lodash/fp/filter'
import flatMap from 'lodash/fp/flatMap'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import map from 'lodash/fp/map'
import { call, put, all } from 'redux-saga/effects'

import {
    makeFetchBidRecommendationsFailure,
    makeFetchSPBidRecommendationsSuccess,
    makeFetchSDBidRecommendationsSuccess,
    makeFetchSBBidRecommendationsSuccess,
    makeApplySuggestedBidSuccess,
    makeApplySuggestedBidFailure,
} from 'actions/ui/shared/bid'
import {
    SPONSORED_PRODUCT,
    SPONSORED_DISPLAY,
    HEADLINE_SEARCH,
} from 'const/factTypes'
import { ARCHIVED } from 'const/resourceStates'
import { MANUAL, AUDIENCE_TARGETING_TYPES } from 'const/targetingTypes'
import { cerebroApiSaga } from 'sagas/common'
import {
    updateTarget,
    getSPTargetBidRecommendationsV3,
    getSDTargetBidRecommendations,
    updateKeyword,
    getSBTargetBidRecommendations,
} from 'services/cerebroApi/orgScope/resourceApi'

const filterSPUnarchivedManualTargetingCampaigns = (res) =>
    res.campaign.state !== ARCHIVED &&
    res.campaign.campaign_type === SPONSORED_PRODUCT

const filterSDUnarchivedManualExpressionCampaigns = (res) =>
    res.campaign.state !== ARCHIVED &&
    res.expression_type === MANUAL &&
    res.campaign.campaign_type === SPONSORED_DISPLAY

const filterSBUnarchivedCampaigns = (res) =>
    res.campaign.state !== ARCHIVED &&
    res.campaign.campaign_type === HEADLINE_SEARCH

/**
 * Create Bid Recommendation call for each resource type
 * @param {string} resource
 * @param {string[]|string} groupByPath
 * @param {(resource) => boolean} resourceFilter a filter to apply to each result[resource] to determine if it should be included in the call to the Api
 * @param {*} bidRecommendationApi
 * @param {*} bidRecommendationFailureAction
 * @param {*} results
 * @return {[]} an array of call() effects
 */
const bidRecommendationCalls = (
    resource,
    groupByPath,
    resourceFilter,
    bidRecommendationApi,
    bidRecommendationFailureAction,
    results
) =>
    flow(
        map((result) => result[resource]),
        filter(resourceFilter),
        groupBy(groupByPath),
        flatMap((resources) => chunk(10)(resources)),
        map((resources) =>
            call(
                bidRecommendationApi,
                resources,
                bidRecommendationFailureAction
            )
        )
    )(results)

function mapKeywordMatchTypeToBidRecommendationExpressionType(matchType) {
    return `KEYWORD_${upperCase(matchType)}_MATCH`
}

function mapBidRecommendationExpressionTypeToKeywordMatchType(expressionType) {
    return expressionType
        .replace('KEYWORD_', '')
        .replace('_MATCH', '')
        .toLowerCase()
}

function* fetchSPKeywordBidRecommendationSaga(
    keywords,
    fetchBidRecommendationsFailure
) {
    // grouped by ad_group_id so we can assume they have the same profile.id and ad_group_id
    const campaignId = keywords[0].campaign.id
    const profileId = keywords[0].profile.id
    const adGroupId = keywords[0].ad_group_id

    // our IDs embed regions, so we need to strip them out
    const amzCampaignId = parseInt(campaignId.replace(/\D/g, ''), 10).toString()
    const amzAdGroupId = parseInt(adGroupId.replace(/\D/g, ''), 10).toString()

    const expressions = keywords.map((keyword) => ({
        type: mapKeywordMatchTypeToBidRecommendationExpressionType(
            keyword.match_type
        ),
        value: keyword.text,
    }))

    let response
    try {
        response = yield call(getSPTargetBidRecommendationsV3, profileId, {
            targetingExpressions: expressions,
            recommendationType: 'BIDS_FOR_EXISTING_AD_GROUP',
            campaignId: amzCampaignId,
            adGroupId: amzAdGroupId,
        })
    } catch (error) {
        yield put(
            fetchBidRecommendationsFailure({
                message: error.message,
                adGroupId,
                profileId,
            })
        )
    }

    const bidRecommendations = response?.data?.bidRecommendations?.find(
        (br) => br.theme === 'CONVERSION_OPPORTUNITIES'
    )

    // adapts the response to the API v2 format since code at other levels
    // is currently coupled to this response format
    const adaptedResponse = {
        data: {
            adGroupId: parseInt(adGroupId.replace(/\D/g, ''), 10),
            recommendations: (
                bidRecommendations?.bidRecommendationsForTargetingExpressions ??
                []
            ).map((recommendation) => {
                return {
                    suggestedBid: {
                        rangeStart: recommendation.bidValues[0].suggestedBid,
                        suggested: recommendation.bidValues[1].suggestedBid,
                        rangeEnd: recommendation.bidValues[2].suggestedBid,
                    },
                    expression: [
                        {
                            type: mapBidRecommendationExpressionTypeToKeywordMatchType(
                                recommendation.targetingExpression.type
                            ),
                            value: recommendation.targetingExpression.value,
                        },
                    ],
                    code: 'SUCCESS',
                }
            }),
        },
    }
    return adaptedResponse
}

const generateSBFetchFn = (resourceType) =>
    function* fetchSBCampaignsBidRecommendationSaga(
        resources,
        fetchBidRecommendationsFailure
    ) {
        // grouped by campaign
        const { campaign } = resources[0]
        try {
            const successResultsArrayProperty = `${resourceType}sBidsRecommendationSuccessResults`
            let resourceIndexProperty = ''
            const requestBody = {
                campaignId: campaign.id.replace(/\D/g, ''),
                adFormat: campaign.ad_format ?? 'productCollection',
            }

            if (resourceType === 'keyword') {
                requestBody.keywords = resources.map((keyword) => ({
                    matchType: keyword.match_type,
                    keywordText: keyword.text,
                }))
                resourceIndexProperty = 'keywordIndex'
            } else if (resourceType === 'target') {
                requestBody.targets = resources.map((target) =>
                    target.expressions.map((exp) => ({
                        type: exp.type,
                        value: exp.value,
                    }))
                )
                resourceIndexProperty = 'targetsIndex'
            }

            const response = yield call(
                getSBTargetBidRecommendations,
                campaign.profile_id,
                requestBody
            )

            const successResults = response?.data[successResultsArrayProperty]

            return (
                successResults?.map((rec) => ({
                    resourceId: resources[rec[resourceIndexProperty]].id,
                    bidRecommendation: rec.recommendedBid,
                })) ?? []
            )
        } catch (error) {
            yield put(
                fetchBidRecommendationsFailure({
                    message: error.message,
                    costType: campaign.cost_type,
                    profileId: campaign.profile_id,
                    campaignId: campaign.id,
                })
            )
        }

        return undefined
    }

export function* fetchKeywordBidRecommendationsSaga(path, results) {
    const SPBidRecommendationResponses = yield all(
        bidRecommendationCalls(
            'keyword',
            'ad_group_id',
            filterSPUnarchivedManualTargetingCampaigns,
            fetchSPKeywordBidRecommendationSaga,
            makeFetchBidRecommendationsFailure([...path, SPONSORED_PRODUCT]),
            results
        )
    )

    const SBBidRecommendationResponses = yield all(
        bidRecommendationCalls(
            'keyword',
            'campaign_id',
            filterSBUnarchivedCampaigns,
            generateSBFetchFn('keyword'),
            makeFetchBidRecommendationsFailure([...path, HEADLINE_SEARCH]),
            results
        )
    )

    yield put(
        makeFetchSPBidRecommendationsSuccess(path)(
            compact(SPBidRecommendationResponses).map((res) => res.data)
        )
    )

    yield put(
        makeFetchSBBidRecommendationsSuccess(path)(
            compact(SBBidRecommendationResponses).flat()
        )
    )
}

function mapTargetExpressionTypeToBidRecommendationExpressionType(
    expressionType
) {
    /*
        queryHighRelMatches
        queryBroadRelMatches
        asinSubstituteRelated
        asinSameAs
        asinAccessoryRelated
        asinCategorySameAs

        PAT_CATEGORY_REFINEMENT - docs don't say how to structure these..
        maybe: https://github.com/amzn/ads-advanced-tools-docs/discussions/240
        asinExpandedFrom
        asinBrandSameAs
        asinPriceBetween
        asinGenreSameAs
        asinReviewRatingLessThan
        asinReviewRatingGreaterThan
        asinReviewRatingBetween
        asinPriceLessThan
        asinPriceGreaterThan
        asinIsPrimeShippingEligible
        asinAgeRangeSameAs
    */
    switch (expressionType) {
        // auto targets
        case 'queryHighRelMatches':
            return 'CLOSE_MATCH'
        case 'queryBroadRelMatches':
            return 'LOOSE_MATCH'
        case 'asinSubstituteRelated':
            return 'SUBSTITUTES'
        case 'asinAccessoryRelated':
            return 'COMPLEMENTS'
        // manual targets
        case 'asinSameAs':
            return 'PAT_ASIN'
        case 'asinCategorySameAs':
            return 'PAT_CATEGORY'

        // PAT_CATEGORY_REFINEMENT - TODO, currently unsupported. We don't surface these in the UI

        default:
            return undefined
    }
}

function mapBidRecommendationExpressionTypeToTargetExpressionType(
    recommendationType
) {
    switch (recommendationType) {
        // auto targets
        case 'CLOSE_MATCH':
            return 'queryHighRelMatches'
        case 'LOOSE_MATCH':
            return 'queryBroadRelMatches'
        case 'SUBSTITUTES':
            return 'asinSubstituteRelated'
        case 'COMPLEMENTS':
            return 'asinAccessoryRelated'
        // manual targets
        case 'PAT_ASIN':
            return 'asinSameAs'
        case 'PAT_CATEGORY':
            return 'asinCategorySameAs'
        default:
            return undefined
    }
}

function* fetchSPTargetBidRecommendationSaga(
    targets,
    fetchBidRecommendationsFailure
) {
    // grouped by ad_group_id so we can assume they have the same profile.id and ad_group_id
    const campaignId = targets[0].campaign.id
    const profileId = targets[0].campaign.profile_id
    const adGroupId = targets[0].ad_group.id

    // our IDs embed regions, so we need to strip them out
    const amzCampaignId = parseInt(campaignId.replace(/\D/g, ''), 10).toString()
    const amzAdGroupId = parseInt(adGroupId.replace(/\D/g, ''), 10).toString()

    const targetingExpressions = targets
        .map((target) => {
            const first_expression = target.expressions[0]
            const translatedExpressionType =
                mapTargetExpressionTypeToBidRecommendationExpressionType(
                    first_expression.type
                )

            return {
                type: translatedExpressionType,
                value: first_expression.value,
            }
        })
        .filter((expression) => expression.type)

    if (targetingExpressions.length === 0) {
        return { data: null }
    }

    let response
    try {
        response = yield call(getSPTargetBidRecommendationsV3, profileId, {
            targetingExpressions,
            recommendationType: 'BIDS_FOR_EXISTING_AD_GROUP',
            campaignId: amzCampaignId,
            adGroupId: amzAdGroupId,
        })
    } catch (error) {
        yield put(
            fetchBidRecommendationsFailure({
                message: error.message,
                adGroupId,
                profileId,
            })
        )
    }

    const bidRecommendations = response?.data?.bidRecommendations?.find(
        (br) => br.theme === 'CONVERSION_OPPORTUNITIES'
    )

    // adapts the response to the API v2 format since code at other levels
    // is currently coupled to this response format
    const adaptedResponse = {
        ...response,
        data: {
            adGroupId: parseInt(adGroupId.replace(/\D/g, ''), 10),
            recommendations: (
                bidRecommendations?.bidRecommendationsForTargetingExpressions ??
                []
            ).map((recommendation) => {
                return {
                    suggestedBid: {
                        rangeStart: recommendation.bidValues[0].suggestedBid,
                        suggested: recommendation.bidValues[1].suggestedBid,
                        rangeEnd: recommendation.bidValues[2].suggestedBid,
                    },
                    expression: [
                        {
                            type: mapBidRecommendationExpressionTypeToTargetExpressionType(
                                recommendation.targetingExpression.type
                            ),
                            value: recommendation.targetingExpression.value,
                        },
                    ],
                    code: 'SUCCESS',
                }
            }),
        },
    }
    return adaptedResponse
}

function* fetchSDTargetBidRecommendationSaga(
    targets,
    fetchBidRecommendationsFailure
) {
    // grouped by campaign
    const { campaign } = targets[0]

    try {
        const requestBody = {
            bidOptimization:
                campaign.cost_type === 'cpc' ? 'conversions' : 'reach',
            costType: campaign.cost_type,
            targetingClauses: targets.map(({ expressions }) => ({
                targetingClause: {
                    expressionType: MANUAL,
                    expression: expressions.map((exp) => ({
                        type: exp.type,
                        value: AUDIENCE_TARGETING_TYPES.includes(exp.type)
                            ? JSON.parse(exp.value.replace(/'/g, '"'))
                            : exp.value,
                    })),
                },
            })),
        }

        const response = yield call(
            getSDTargetBidRecommendations,
            campaign.profile_id,
            requestBody
        )

        return (
            response?.data?.bidRecommendations?.map((rec, idx) => ({
                targetId: targets[idx].id,
                bidRecommendation: rec,
            })) ?? []
        )
    } catch (error) {
        yield put(
            fetchBidRecommendationsFailure({
                message: error.message,
                costType: campaign.cost_type,
                profileId: campaign.profile_id,
                campaignId: campaign.id,
            })
        )
    }

    return undefined
}

export function* fetchTargetBidRecommendationsSaga(path, results) {
    const resourceName = 'target'
    const SPBidRecommendationResponses = yield all([
        ...bidRecommendationCalls(
            resourceName,
            'ad_group_id',
            filterSPUnarchivedManualTargetingCampaigns,
            fetchSPTargetBidRecommendationSaga,
            makeFetchBidRecommendationsFailure([...path, SPONSORED_PRODUCT]),
            results
        ),
    ])

    const SDBidRecommendationResponses = yield all([
        ...bidRecommendationCalls(
            resourceName,
            'campaign_id',
            filterSDUnarchivedManualExpressionCampaigns,
            fetchSDTargetBidRecommendationSaga,
            makeFetchBidRecommendationsFailure([...path, SPONSORED_DISPLAY]),
            results
        ),
    ])

    const SBBidRecommendationResponses = yield all(
        bidRecommendationCalls(
            resourceName,
            'campaign_id',
            filterSBUnarchivedCampaigns,
            generateSBFetchFn(resourceName),
            makeFetchBidRecommendationsFailure([...path, HEADLINE_SEARCH]),
            results
        )
    )

    yield put(
        makeFetchSPBidRecommendationsSuccess(path)(
            compact(SPBidRecommendationResponses).map((res) => res.data)
        )
    )
    yield put(
        makeFetchSDBidRecommendationsSuccess(path)(
            compact(SDBidRecommendationResponses).flat()
        )
    )

    yield put(
        makeFetchSBBidRecommendationsSuccess(path)(
            compact(SBBidRecommendationResponses).flat()
        )
    )
}

export function* applySuggestedBidWorker(action) {
    const {
        path,
        data: { keywordId, targetId, data },
    } = action.payload

    const id = keywordId || targetId
    const service = keywordId ? updateKeyword : updateTarget

    try {
        yield call(
            cerebroApiSaga,
            makeApplySuggestedBidSuccess(path),
            service,
            id,
            data
        )
    } catch (error) {
        yield put(makeApplySuggestedBidFailure(path)(error))
    }
}
