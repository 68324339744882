import { useEffect, useRef, useState } from 'react'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import { DEBOUNCE_TIMEOUT, MIN_SEARCH_CHARS } from 'configuration/typeahead'
import {
    CerebroPaginatedResponse,
    DatadogActionName,
    TypeaheadOption,
} from 'types'

import useCerebroApiRequest from './useCerebroApiRequest'
import useMonitorLoadTime from './useMonitorLoadTime'

type ReturnValue = [
    TypeaheadOption[],
    boolean,
    (value: any) => void,
    () => void,
    null | string,
]

export type ApiFuncResponse<T> = Promise<CerebroPaginatedResponse<T>>

interface Params<T> {
    apiSearchFunc: (query: string) => ApiFuncResponse<T> | null | undefined
    optionFormatter: (option: T) => TypeaheadOption
    defaultOptions?: T[]
    prefetchApiFunc?: () => ApiFuncResponse<T> | null | undefined
    resultsDataIndex?: string
}

/**
 * Hook that performs searches for typeahead options and optionally prefetches data
 *
 * IMPORTANT: when providing the `prefetchApiFunc` callback option, make sure this
 * callback function is wrapped in `React.useCallback`. This ensures the prefetch
 * function will only be executed on initial mount.
 */
function useTypeahead<T = object>({
    apiSearchFunc,
    optionFormatter,
    defaultOptions = [],
    prefetchApiFunc,
    resultsDataIndex = 'data.results',
}: Params<T>): ReturnValue {
    const mounted = useRef(false)
    const searchQuery = useRef<null | string>(null)
    const [prefetchResults, setPrefetchResults] = useState<T[]>([])
    const [prefetchLoading, setPrefetchLoading] = useState<boolean>(false)

    const [searchResults, setSearchResults] = useState<T[]>([])
    const [searchLoading, setSearchLoading] = useState<boolean>(false)

    const makeCerebroApiRequest = useCerebroApiRequest()

    useEffect(() => {
        mounted.current = true
        const prefetch = async (): Promise<void> => {
            if (prefetchApiFunc) {
                setPrefetchLoading(true)
                const apiFn = prefetchApiFunc()
                if (apiFn) {
                    const preResults = await makeCerebroApiRequest({
                        request: apiFn,
                    })
                    if (mounted.current) {
                        if (preResults) {
                            setPrefetchResults(
                                get(preResults, resultsDataIndex)
                            )
                        }
                        setPrefetchLoading(false)
                    }
                }
            }
        }
        prefetch()
        return () => {
            mounted.current = false
        }
    }, [makeCerebroApiRequest, prefetchApiFunc, resultsDataIndex])

    async function search(query: string): Promise<void> {
        setSearchLoading(true)
        try {
            const searchFn = apiSearchFunc(query)
            if (searchFn) {
                const response = await makeCerebroApiRequest({
                    request: searchFn,
                })
                setSearchResults(get(response, resultsDataIndex, []))
            }
        } finally {
            setSearchLoading(false)
        }
    }

    function handleChangeInput(value: string): void {
        if (value.length < MIN_SEARCH_CHARS) {
            return
        }
        searchQuery.current = value
        search(value)
    }

    function resetLoadingState(): void {
        // Do not display loading state after the user has selected an option
        setSearchLoading(false)
        setPrefetchLoading(false)
    }

    const loading = searchLoading || prefetchLoading

    useMonitorLoadTime({
        actionName: DatadogActionName.LOAD_TYPEAHEAD,
        loading,
        typeaheadContext: {
            query: searchQuery.current,
        },
    })

    return [
        uniqBy(
            [...searchResults, ...defaultOptions, ...prefetchResults].map(
                optionFormatter
            ),
            'value'
        ),
        loading,
        debounce(handleChangeInput, DEBOUNCE_TIMEOUT),
        resetLoadingState,
        searchQuery.current,
    ]
}

export default useTypeahead
