import { ReactElement, useEffect } from 'react'

import queryString from 'query-string'
import { useLocation } from 'react-router'

import { getSignUpFormInvitationRequest } from 'actions/auth'
import { useAction, useAuthDomainValue } from 'hooks'
import { OrganizationInvitation } from 'types'

import { CreateAccountForm } from './Forms'

const InvitationSignUpPage = (): ReactElement | null => {
    const location = useLocation()
    const search = queryString.parse(location.search)
    const { token }: { token?: string } = search

    const getInvitation = useAction(getSignUpFormInvitationRequest)
    const invitation: OrganizationInvitation = useAuthDomainValue('invitation')

    useEffect(() => {
        if (token) {
            getInvitation({ token })
        }
    }, [getInvitation, token])

    if (!invitation) {
        return null
    }

    return <CreateAccountForm invitation={invitation} token={token} />
}

export default InvitationSignUpPage
