import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableSuccess,
    makeFetchTableRequest,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { keywordSearchTermsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { TARGETING_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/keywordQueryFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectCurrencyCode,
    selectVisibleCombinedFilters,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getKeywordQueryFacts,
    getKeywordQueryFactsExport,
} from 'services/cerebroApi/orgScope/keywordQueryFactsApi'

const TAB_PATH = [TARGETING_SUMMARY_PAGE, 'keywordSearchTerms']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount: boolean): any {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        keywordSearchTermsSummaryTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by:
            'query,keyword_id,ad_group_id,campaign_id,portfolio_id,profile_id',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getKeywordQueryFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga(path: string[]): any {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        path,
        keywordSearchTermsSummaryTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = 'Keyword Search Terms Report'

    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by:
            'query,keyword_id,ad_group_id,campaign_id,portfolio_id,profile_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getKeywordQueryFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga(): any {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
