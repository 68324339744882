import {
    advertiserIdRenderer,
    orderIdRenderer,
    lineItemIdRenderer,
    orderBudgetRenderer,
    lineItemBudgetRenderer,
    lineItemBidRenderer,
    lineItemTypeRenderer,
} from 'components/FieldRenderers/Renderers'
import { brandId } from 'configuration/fieldCreators/brand'
import {
    advertiserId,
    lineItemBid,
    lineItemBudget,
    lineItemId,
    lineItemType,
    orderBudget,
    orderId,
    removeLineItemFromLabel,
    removeOrderFromLabel,
} from 'configuration/fieldCreators/dsp'
import { labels } from 'configuration/fieldCreators/labels'
import {
    DspAdvertiserGroupBy,
    DspCampaignFact,
    DspLineItemGroupBy,
    DspOrderGroupBy,
    Field,
} from 'types'

import { DSP_METRICS_COLUMNS_PAGES } from '../dataSources/dsp'

export type DspCampaignFactRecord = DspCampaignFact<
    DspAdvertiserGroupBy & DspOrderGroupBy & DspLineItemGroupBy
>

export const dspAdvertisersTableColumnsConfig: Field<DspCampaignFactRecord>[] =
    [
        advertiserId<DspCampaignFactRecord>({
            renderOptions: { render: advertiserIdRenderer },
        }),
        brandId(),
        ...(DSP_METRICS_COLUMNS_PAGES as Field<DspCampaignFactRecord>[]),
    ]

export const dspOrdersTableColumnsConfig: Field<DspCampaignFactRecord>[] = [
    orderId<DspCampaignFactRecord>({
        renderOptions: { render: orderIdRenderer },
    }),
    orderBudget<DspCampaignFactRecord>({
        renderOptions: { render: orderBudgetRenderer },
    }),
    advertiserId<DspCampaignFactRecord>({
        renderOptions: { render: advertiserIdRenderer },
    }),
    brandId(),
    labels({ dataIndex: ['order', 'labels'] }),
    ...(DSP_METRICS_COLUMNS_PAGES as Field<DspCampaignFactRecord>[]),
]

export const dspLineItemsTableColumnsConfig: Field<DspCampaignFactRecord>[] = [
    lineItemId<DspCampaignFactRecord>({
        renderOptions: { render: lineItemIdRenderer },
    }),
    lineItemType<DspCampaignFactRecord>({
        renderOptions: { render: lineItemTypeRenderer },
    }),
    lineItemBudget<DspCampaignFactRecord>({
        renderOptions: {
            render: (cellRenderProps) =>
                lineItemBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['line_item', 'budget'],
                }),
        },
    }),
    lineItemBid<DspCampaignFactRecord>({
        renderOptions: {
            render: (cellRenderProps) =>
                lineItemBidRenderer({
                    cellRenderProps,
                    dataIndex: ['line_item', 'bid'],
                }),
        },
    }),
    orderId<DspCampaignFactRecord>({
        renderOptions: { render: orderIdRenderer },
    }),
    orderBudget<DspCampaignFactRecord>({
        renderOptions: { render: orderBudgetRenderer },
    }),
    advertiserId<DspCampaignFactRecord>({
        renderOptions: { render: advertiserIdRenderer },
    }),
    brandId(),
    labels({ dataIndex: ['line_item', 'labels'] }),
    ...(DSP_METRICS_COLUMNS_PAGES as Field<DspCampaignFactRecord>[]),
]

export const labelDspLineItemsTableColumnsConfig: Field<DspCampaignFactRecord>[] =
    [...dspLineItemsTableColumnsConfig, removeLineItemFromLabel()]

export const labelDspOrdersTableColumnsConfig: Field<DspCampaignFactRecord>[] =
    [...dspOrdersTableColumnsConfig, removeOrderFromLabel()]
