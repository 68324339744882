import { Translation } from 'react-i18next'

const widgetsTranslation = {
    campaign_facts: {
        name: (
            <Translation>
                {(t) =>
                    t('widgets:campaign_facts.name', 'Advertising Campaigns')
                }
            </Translation>
        ),
    },
    keyword_facts: {
        name: (
            <Translation>
                {(t) => t('widgets:keyword_facts.name', 'Advertising Keywords')}
            </Translation>
        ),
    },
    product_ad_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:product_ad_facts.name',
                        'Advertising Products Ads'
                    )
                }
            </Translation>
        ),
    },
    target_facts: {
        name: (
            <Translation>
                {(t) => t('widgets:target_facts.name', 'Advertising Targets')}
            </Translation>
        ),
    },
    sov_search_results: {
        name: (
            <Translation>
                {(t) =>
                    t('widgets:sov_search_results.name', 'Shelf Intelligence')
                }
            </Translation>
        ),
    },
    vc_sales_diagnostic: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_sales_diagnostic.name',
                        'Vendor Central - Manufacturer Sales Diagnostic Data (Legacy Integration)'
                    )
                }
            </Translation>
        ),
    },
    vc_source_sales_diagnostic: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_source_sales_diagnostic.name',
                        'Vendor Central - Sourcing Sales Diagnostic Data (Legacy Integration)'
                    )
                }
            </Translation>
        ),
    },
    vc_traffic_diagnostic: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_traffic_diagnostic.name',
                        'Vendor Central - Traffic Diagnostic (Legacy Integration)'
                    )
                }
            </Translation>
        ),
    },
    vc_inventory_health: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_inventory_health.name',
                        'Vendor Central - Inventory Health (Legacy Integration)'
                    )
                }
            </Translation>
        ),
    },
    sc_sales_and_traffic: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:sc_sales_and_traffic.name',
                        'Seller Central - Sales and Traffic'
                    )
                }
            </Translation>
        ),
        warnings: [
            <Translation>
                {(t) =>
                    t(
                        'widgets:sc_sales_and_traffic.warnings',
                        'Advertising metrics in this data source include SP/SD data only.'
                    )
                }
            </Translation>,
        ],
    },
    sc_all_orders: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:sc_all_orders.name',
                        'Seller Central - All Orders'
                    )
                }
            </Translation>
        ),
    },
    vc_api_sales_diagnostic: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_api_sales_diagnostic.name',
                        'Vendor Central - Sales and Traffic Manufacturing View'
                    )
                }
            </Translation>
        ),
    },
    vc_api_sales_diagnostic_sourcing: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_api_sales_diagnostic_sourcing.name',
                        'Vendor Central - Sales and Traffic Sourcing View'
                    )
                }
            </Translation>
        ),
    },
    vc_api_inventory_health: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_api_inventory_health.name',
                        'Vendor Central - Inventory Health'
                    )
                }
            </Translation>
        ),
    },
    vc_api_inventory_manufacturing: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_api_inventory_manufacturing.name',
                        'Vendor Central - Inventory Manufacturing'
                    )
                }
            </Translation>
        ),
    },
    vc_api_inventory_sourcing: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:vc_api_inventory_sourcing.name',
                        'Vendor Central - Inventory Sourcing'
                    )
                }
            </Translation>
        ),
    },
    dsp_campaign_facts: {
        name: (
            <Translation>
                {(t) =>
                    t('widgets:dsp_campaign_facts.name', 'DSP Campaign Facts')
                }
            </Translation>
        ),
    },
    dsp_inventory_facts: {
        name: (
            <Translation>
                {(t) => t('widgets:dsp_inventory_facts.name', 'DSP Inventory')}
            </Translation>
        ),
    },
    changelog: {
        name: (
            <Translation>
                {(t) => t('widgets:changelog.name', 'Changelog')}
            </Translation>
        ),
    },
    asin_usage: {
        name: (
            <Translation>
                {(t) => t('widgets:asin_usage.name', 'ASIN Usage')}
            </Translation>
        ),
    },
    wm_marketplace_order_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_marketplace_order_facts.name',
                        'Walmart Marketplace'
                    )
                }
            </Translation>
        ),
    },
    wm_advertising_campaign_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_advertising_campaign_facts.name',
                        'Walmart Campaigns'
                    )
                }
            </Translation>
        ),
    },
    wm_advertising_placement_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_advertising_placement_facts.name',
                        'Walmart Placements'
                    )
                }
            </Translation>
        ),
    },
    wm_advertising_platform_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_advertising_platform_facts.name',
                        'Walmart Platforms'
                    )
                }
            </Translation>
        ),
    },
    wm_advertising_item_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_advertising_item_facts.name',
                        'Walmart Ad Items'
                    )
                }
            </Translation>
        ),
    },
    wm_advertising_keyword_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:wm_advertising_keyword_facts.name',
                        'Walmart Ad Keywords'
                    )
                }
            </Translation>
        ),
    },
    ams_stream_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:ams_stream_facts.name',
                        'Amazon Marketing Stream Data'
                    )
                }
            </Translation>
        ),
    },
    org_facts: {
        name: (
            <Translation>
                {(t) => t('widgets:org_facts.name', 'Organization Wide Facts')}
            </Translation>
        ),
    },
    segment_facts: {
        name: (
            <Translation>
                {(t) => t('widgets:segment_facts.name', 'Segments')}
            </Translation>
        ),
    },
    sb_purchased_product_facts: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'widgets:sb_purchased_product_facts.name',
                        'SB Purchased Products'
                    )
                }
            </Translation>
        ),
    },
}

export default widgetsTranslation
