import { AnchorHTMLAttributes, ImgHTMLAttributes, ReactElement } from 'react'

import Markdown from 'react-markdown'
import { Link as RouterLink } from 'react-router-dom'
import remarkGfm from 'remark-gfm'

import { ExternalLinkIcon, TrendDownIcon, TrendUpIcon } from 'components/Icons'
import { AsinLink, SellerLink } from 'components/Links'
import { CountryCode } from 'types'

import styles from './styles.scss'

interface MarkdownRendererProps {
    markdown?: string | null
}

const components = {
    a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
        if (!props.href) {
            return <a {...props} />
        }
        if (props.href === 'positive_change') {
            return (
                <span
                    className={`${styles['value-change']} ${styles.positive}`}
                >
                    <span style={{ fontSize: '14px' }}>
                        <TrendUpIcon />
                    </span>
                    {props.children}
                </span>
            )
        }
        if (props.href === 'negative_change') {
            return (
                <span
                    className={`${styles['value-change']} ${styles.negative}`}
                >
                    <span style={{ fontSize: '14px' }}>
                        <TrendDownIcon />
                    </span>
                    {props.children}
                </span>
            )
        }

        if (props.href === 'asin_link') {
            const [countryCode, asin] = (
                props.children as Array<string>
            )[0].split('_')
            return (
                <AsinLink
                    asin={asin}
                    countryCode={countryCode as CountryCode}
                />
            )
        }

        if (props.href === 'seller_link') {
            const [countryCode, sellerId] = (
                props.children as Array<string>
            )[0].split('_')
            return (
                <SellerLink
                    sellerId={sellerId}
                    countryCode={countryCode as CountryCode}
                />
            )
        }

        const isLocal = props.href.startsWith('/')
        if (isLocal) {
            const localLink = (ASSET_PATH + props.href).replace('//', '/')
            return <RouterLink {...props} to={localLink} />
        }
        return (
            <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children} <ExternalLinkIcon />
            </a>
        )
    },

    img(props: ImgHTMLAttributes<HTMLImageElement>) {
        if (props.alt === 'product_img') {
            return (
                <img
                    src={props.src}
                    alt={props.alt}
                    style={{ width: 50, height: 50 }}
                />
            )
        }
        return <img src={props.src} alt={props.alt} />
    },
}

export default function MarkdownRenderer({
    markdown,
}: MarkdownRendererProps): ReactElement | null {
    if (!markdown) {
        return null
    }

    return (
        <Markdown components={components} plugins={[remarkGfm]}>
            {markdown}
        </Markdown>
    )
}
