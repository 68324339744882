export const PAUSED = 'paused'
export const ENABLED = 'enabled'
export const DISABLED = 'disabled'
export const ARCHIVED = 'archived'
const DELETED = 'deleted'
export const DRAFT = 'draft'
export const ACTIVE = 'active'

export const KEYWORD_STATE_LABELS = {
    [ENABLED]: 'Enabled Keywords',
    [PAUSED]: 'Paused Keywords',
    [ARCHIVED]: 'Archived Keywords',
}

export const TARGET_STATE_LABELS = {
    [ENABLED]: 'Enabled Targets',
    [PAUSED]: 'Paused Targets',
    [ARCHIVED]: 'Archived Targets',
}

export const STATE_FIELD_OPTIONS = {
    [ENABLED]: 'Enabled',
    [PAUSED]: 'Paused',
    [ARCHIVED]: 'Archived',
}

const NEGATIVE_STATE_FIELD_OPTIONS = {
    [ENABLED]: 'Enabled',
    [PAUSED]: 'Paused',
    [ARCHIVED]: 'Archived',
    [DELETED]: 'Deleted',
}

export const WALMART_AD_ITEM_STATE_FIELD_OPTIONS = {
    [ENABLED]: 'Enabled',
    [DISABLED]: 'Disabled',
}

export const SEGMENT_STATE_FIELD_OPTIONS = {
    [ACTIVE]: 'Active',
    [PAUSED]: 'Paused',
}

export const CAMPAIGN_STATES = STATE_FIELD_OPTIONS
export const KEYWORD_STATES = STATE_FIELD_OPTIONS
export const NEGATIVE_KEYWORD_STATES = NEGATIVE_STATE_FIELD_OPTIONS
export const PRODUCT_AD_STATES = STATE_FIELD_OPTIONS
export const TARGET_STATES = STATE_FIELD_OPTIONS
export const SOV_KEYWORD_STATES = STATE_FIELD_OPTIONS
export const SEGMENT_STATES = SEGMENT_STATE_FIELD_OPTIONS
