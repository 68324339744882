import omit from 'lodash/omit'
import { Translation } from 'react-i18next'

import {
    AMS_METRICS_CONFIG,
    AMS_WITH_SD_METRICS_CONFIG,
    TARGET_SUPPORTED_AMS_METRICS_CONFIG,
} from 'configuration/dataSources/ams'
import { SOV_BRAND_METRICS_CONFIG } from 'configuration/dataSources/sov'
import { RulebookDataSourceKey } from 'const/dataSources'
import {
    AD_ACCOUNTS,
    AD_GROUP_STATES,
    AD_GROUPS,
    AD_ITEMS,
    ADVANCED_AUTOMATIONS,
    ADVANCED_TEXT,
    BRANDS,
    CAMPAIGN_AD_FORMATS,
    CAMPAIGN_BUDGET,
    CAMPAIGN_START_DATE,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    CATALOG_ASINS,
    COUNTRIES,
    DSP_ADVERTISERS,
    DSP_LINE_ITEMS,
    DSP_ORDERS,
    FACT_TYPES,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    KEYWORDS,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    PRODUCT_ASINS,
    PRODUCT_TITLES,
    REGIONS,
    SC_INTEGRATIONS,
    SOV_BRANDS,
    SOV_COUNTRIES,
    SOV_FOLDS,
    SOV_KEYWORDS,
    SOV_LANGUAGES,
    SOV_PLATFORMS,
    SOV_RANK,
    SOV_RESULT_TYPES,
    SOV_STATES,
    TARGET_STATES,
    TARGETS,
    WM_ADVERTISING_GROUPS,
    WM_ADVERTISING_ITEMS,
    WM_CAMPAIGNS,
    WM_KEYWORDS,
} from 'const/filters'
import {
    ENABLED,
    KEYWORD_STATE_LABELS,
    TARGET_STATE_LABELS,
} from 'const/resourceStates'
import {
    AlertCategories,
    AlertCategory,
    AlertDataSourceConfiguration,
    type AlertDataSources,
} from 'types'

import {
    BID_ACTIONS,
    BUDGET_ACTIONS,
    BUDGET_CAP_ACTIONS,
    DO_NOTHING_ACTIONS,
    LABEL_ACTIONS,
    PAUSE_ENABLE_RESOURCE_ACTIONS,
    PRODUCT_ADS_ACTIONS,
} from './actions'
import { DSP_METRICS_CONFIG } from './dataSources/dsp'
import {
    VC_API_INVENTORY_MANUFACTURING_METRICS_CONFIG,
    VC_API_INVENTORY_SOURCING_METRICS_CONFIG,
    VC_API_SALES_DIAGNOSTIC_METRICS_CONFIG,
    VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_CONFIG,
    VC_INVENTORY_HEALTH_METRICS_CONFIG,
} from './dataSources/vendorCentral'
import { WM_ADVERTISING_METRICS_CONFIG } from './dataSources/walmartAdvertising'
import {
    CAMPAIGN_LABEL_CONFIG,
    PORTFOLIO_LABEL_CONFIG,
    PRODUCT_LABEL_CONFIG,
    SOV_LABEL_CONFIG,
} from './labels'

export const DATA_SOURCES: AlertDataSources = {
    campaign_facts: {
        name: 'Campaigns',
        key: CAMPAIGNS,
        filters: [
            CAMPAIGNS,
            FACT_TYPES,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            LABELS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_AD_FORMATS,
            CAMPAIGN_BUDGET,
            ADVANCED_TEXT,
            ADVANCED_AUTOMATIONS,
            METRIC_FILTERS,
            CAMPAIGN_START_DATE,
        ],
        metricConfig: AMS_WITH_SD_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [
            ...BUDGET_ACTIONS,
            ...LABEL_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'campaign_facts',
        defaultMetric: 'clicks__sum',
    },
    keyword_facts: {
        name: 'Keywords',
        key: KEYWORDS,
        filters: [
            KEYWORDS,
            FACT_TYPES,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            LABELS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_START_DATE,
            AD_GROUP_STATES,
            KEYWORD_MATCH_TYPES,
            KEYWORD_STATES,
            ADVANCED_TEXT,
            METRIC_FILTERS,
        ],
        metricConfig: AMS_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [
            ...BID_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        defaultFilters: {
            [KEYWORD_STATES]: [
                {
                    value: ENABLED,
                    label: KEYWORD_STATE_LABELS[ENABLED],
                },
            ],
        },
        minLookbackDays: 1,
        metadataKey: 'keyword_facts',
        defaultMetric: 'clicks__sum',
    },
    portfolio_facts: {
        name: 'Portfolios',
        key: PORTFOLIOS,
        filters: [
            PORTFOLIOS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            LABELS,
            METRIC_FILTERS,
        ],
        metricConfig: AMS_WITH_SD_METRICS_CONFIG,
        labelConfig: PORTFOLIO_LABEL_CONFIG,
        actionConfig: [
            ...BUDGET_CAP_ACTIONS,
            ...LABEL_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'portfolio_facts',
        defaultMetric: 'clicks__sum',
    },
    ad_group_facts: {
        name: 'Ad Groups',
        key: AD_GROUPS,
        filters: [
            AD_GROUPS,
            FACT_TYPES,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            LABELS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            AD_GROUP_STATES,
            ADVANCED_TEXT,
            METRIC_FILTERS,
        ],
        metricConfig: AMS_WITH_SD_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [
            ...BID_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'ad_group_facts',
        defaultMetric: 'clicks__sum',
    },
    target_facts: {
        name: 'Targets',
        key: TARGETS,
        filters: [
            TARGETS,
            FACT_TYPES,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            LABELS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_START_DATE,
            CAMPAIGN_TARGETING_TYPES,
            AD_GROUP_STATES,
            TARGET_STATES,
            ADVANCED_TEXT,
            METRIC_FILTERS,
        ],
        metricConfig: TARGET_SUPPORTED_AMS_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [
            ...BID_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        defaultFilters: {
            [TARGET_STATES]: [
                {
                    value: ENABLED,
                    label: TARGET_STATE_LABELS[ENABLED],
                },
            ],
        },
        minLookbackDays: 1,
        metadataKey: 'target_facts',
        defaultMetric: 'clicks__sum',
    },
    dsp_line_item_facts: {
        name: 'Line Items',
        key: DSP_LINE_ITEMS,
        filters: [DSP_LINE_ITEMS, BRANDS, DSP_ADVERTISERS, DSP_ORDERS, LABELS],
        metricConfig: DSP_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [
            ...BUDGET_ACTIONS,
            ...LABEL_ACTIONS,
            ...BID_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'dsp_campaign_facts',
        defaultMetric: 'click_throughs__sum',
    },
    dsp_order_facts: {
        name: 'Orders',
        key: DSP_ORDERS,
        filters: [DSP_ORDERS, BRANDS, DSP_ADVERTISERS, DSP_LINE_ITEMS, LABELS],
        metricConfig: DSP_METRICS_CONFIG,
        labelConfig: CAMPAIGN_LABEL_CONFIG,
        actionConfig: [...LABEL_ACTIONS, ...DO_NOTHING_ACTIONS],
        minLookbackDays: 1,
        metadataKey: 'dsp_campaign_facts',
        defaultMetric: 'click_throughs__sum',
    },
    sov_keyword_facts: {
        name: 'Keywords',
        key: SOV_KEYWORDS,
        filters: [
            SOV_KEYWORDS,
            SOV_COUNTRIES,
            SOV_FOLDS,
            SOV_LANGUAGES,
            SOV_PLATFORMS,
            SOV_RESULT_TYPES,
            SOV_STATES,
            SOV_RANK,
            LABELS,
        ],
        metricConfig: SOV_BRAND_METRICS_CONFIG,
        labelConfig: SOV_LABEL_CONFIG,
        actionConfig: [],
        requiredFilters: [SOV_BRANDS],
        minLookbackDays: 1,
        metadataKey: 'sov',
        metricCategoryOrder: [
            'Shelf Intelligence',
            'Advertising + Shelf Intelligence',
            'Advertising',
        ],
        defaultMetric: 'brand_rank_weight__pct',
    },
    vc_sales_diagnostic_facts: {
        name: 'ASINs (Sales and Traffic Manufacturing)',
        key: PRODUCT_ASINS,
        filters: [
            CATALOG_ASINS,
            COUNTRIES,
            PRODUCT_TITLES,
            LABELS,
            SC_INTEGRATIONS,
            METRIC_FILTERS,
        ],
        metricConfig: VC_API_SALES_DIAGNOSTIC_METRICS_CONFIG,
        labelConfig: PRODUCT_LABEL_CONFIG,
        actionConfig: PRODUCT_ADS_ACTIONS,
        minLookbackDays: 3,
        metadataKey:
            'vendor_central_selling_partner_api_sales_diagnostic_facts',
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
        defaultMetric: 'ordered_units__sum',
    },
    vc_inventory_health_facts: {
        name: 'ASINs (Inventory Health)',
        key: PRODUCT_ASINS,
        filters: [
            CATALOG_ASINS,
            COUNTRIES,
            PRODUCT_TITLES,
            LABELS,
            SC_INTEGRATIONS,
            METRIC_FILTERS,
        ],
        metricConfig: VC_INVENTORY_HEALTH_METRICS_CONFIG,
        labelConfig: PRODUCT_LABEL_CONFIG,
        actionConfig: PRODUCT_ADS_ACTIONS,
        minLookbackDays: 3,
        metadataKey:
            'vendor_central_selling_partner_api_inventory_health_facts',
        defaultMetric: 'sellable_on_hand_units__sum',
    },
    vc_inventory_manufacturing_facts: {
        name: 'ASINs (Inventory Manufacturing)',
        key: PRODUCT_ASINS,
        filters: [
            CATALOG_ASINS,
            COUNTRIES,
            PRODUCT_TITLES,
            LABELS,
            SC_INTEGRATIONS,
            METRIC_FILTERS,
        ],
        metricConfig: VC_API_INVENTORY_MANUFACTURING_METRICS_CONFIG,
        labelConfig: PRODUCT_LABEL_CONFIG,
        actionConfig: PRODUCT_ADS_ACTIONS,
        minLookbackDays: 3,
        metadataKey:
            'vendor_central_selling_partner_api_inventory_manufacturing_facts',
        defaultMetric: 'sellable_on_hand_inventory_units__last_value',
    },
    vc_inventory_sourcing_facts: {
        name: 'ASINs (Inventory Sourcing)',
        key: PRODUCT_ASINS,
        filters: [
            CATALOG_ASINS,
            COUNTRIES,
            PRODUCT_TITLES,
            LABELS,
            SC_INTEGRATIONS,
            METRIC_FILTERS,
        ],
        metricConfig: VC_API_INVENTORY_SOURCING_METRICS_CONFIG,
        labelConfig: PRODUCT_LABEL_CONFIG,
        actionConfig: PRODUCT_ADS_ACTIONS,
        minLookbackDays: 3,
        metadataKey:
            'vendor_central_selling_partner_api_inventory_sourcing_facts',
        defaultMetric: 'sellable_on_hand_inventory_units__last_value',
    },
    vc_sales_diagnostic_sourcing_facts: {
        name: 'ASINs (Sales and Traffic Sourcing)',
        key: PRODUCT_ASINS,
        filters: [
            CATALOG_ASINS,
            COUNTRIES,
            PRODUCT_TITLES,
            LABELS,
            SC_INTEGRATIONS,
            METRIC_FILTERS,
        ],
        metricConfig: VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_CONFIG,
        labelConfig: PRODUCT_LABEL_CONFIG,
        actionConfig: PRODUCT_ADS_ACTIONS,
        minLookbackDays: 3,
        metadataKey:
            'vendor_central_selling_partner_api_sales_diagnostic_sourcing_facts',
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
        defaultMetric: 'shipped_units__sum',
    },
    wm_advertising_campaign_facts: {
        name: 'Campaigns',
        key: CAMPAIGNS,
        filters: [WM_CAMPAIGNS, CAMPAIGN_STATES, AD_ACCOUNTS, METRIC_FILTERS],
        metricConfig: WM_ADVERTISING_METRICS_CONFIG,
        actionConfig: [
            ...BUDGET_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'walmart_advertising_campaign_facts',
        defaultMetric: 'ad_spend__sum',
    },
    wm_advertising_ad_group_facts: {
        name: 'Ad Groups',
        key: AD_GROUPS,
        filters: [
            WM_ADVERTISING_GROUPS,
            AD_GROUP_STATES,
            AD_ACCOUNTS,
            METRIC_FILTERS,
        ],
        metricConfig: WM_ADVERTISING_METRICS_CONFIG,
        actionConfig: [...PAUSE_ENABLE_RESOURCE_ACTIONS, ...DO_NOTHING_ACTIONS],
        minLookbackDays: 1,
        metadataKey: 'walmart_advertising_ad_group_facts',
        defaultMetric: 'ad_spend__sum',
    },
    wm_advertising_keyword_facts: {
        name: 'Keywords',
        key: KEYWORDS,
        filters: [WM_KEYWORDS, KEYWORD_STATES, AD_ACCOUNTS, METRIC_FILTERS],
        metricConfig: WM_ADVERTISING_METRICS_CONFIG,
        actionConfig: [
            ...BID_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'walmart_advertising_keyword_facts',
        defaultMetric: 'ad_spend__sum',
    },
    wm_advertising_ad_item_facts: {
        name: 'Ad Items',
        key: AD_ITEMS,
        filters: [WM_ADVERTISING_ITEMS, METRIC_FILTERS, AD_ACCOUNTS],
        metricConfig: WM_ADVERTISING_METRICS_CONFIG,
        actionConfig: [
            ...BID_ACTIONS,
            ...PAUSE_ENABLE_RESOURCE_ACTIONS,
            ...DO_NOTHING_ACTIONS,
        ],
        minLookbackDays: 1,
        metadataKey: 'walmart_advertising_item_facts',
        defaultMetric: 'ad_spend__sum',
    },
}

export const ALERTS_DATA_SOURCES = DATA_SOURCES

export const RULEBOOKS_DATA_SOURCES: Record<
    RulebookDataSourceKey,
    AlertDataSourceConfiguration
> = omit(DATA_SOURCES, ['sov_keyword_facts'])

export const DATA_CATEGORIES: AlertCategories = {
    [AlertCategory.AMS_ADVERTISING]: {
        name: (
            <Translation>
                {(t) =>
                    t('alerts:categories.amd_advertising', 'Amazon Advertising')
                }
            </Translation>
        ),
        dataSources: [
            'campaign_facts',
            'portfolio_facts',
            'keyword_facts',
            'ad_group_facts',
            'target_facts',
        ],
    },
    [AlertCategory.AMS_VENDOR_CENTRAL]: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'alerts:categories.ams_vendor_central',
                        'Amazon Vendor Central'
                    )
                }
            </Translation>
        ),
        dataSources: [
            'vc_sales_diagnostic_facts',
            'vc_sales_diagnostic_sourcing_facts',
            'vc_inventory_manufacturing_facts',
            'vc_inventory_sourcing_facts',
        ],
    },
    [AlertCategory.AMS_DSP]: {
        name: (
            <Translation>
                {(t) => t('alerts:categories.ams_dsp', 'Amazon DSP')}
            </Translation>
        ),
        dataSources: ['dsp_line_item_facts'],
    },
    [AlertCategory.AMS_SHELF_INTELLIGENCE]: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'alerts:categories.ams_shelf_intelligence',
                        'Amazon Shelf Intelligence'
                    )
                }
            </Translation>
        ),
        dataSources: ['sov_keyword_facts'],
    },
    [AlertCategory.WALMART]: {
        name: (
            <Translation>
                {(t) => t('alerts:categories.walmart', 'Walmart')}
            </Translation>
        ),
        dataSources: [
            'wm_advertising_campaign_facts',
            'wm_advertising_ad_group_facts',
            'wm_advertising_keyword_facts',
            'wm_advertising_ad_item_facts',
        ],
    },
}

export const ALERTS_DATA_CATEGORIES = DATA_CATEGORIES

export const RULEBOOKS_DATA_CATEGORIES = omit(DATA_CATEGORIES, [
    AlertCategory.AMS_SHELF_INTELLIGENCE,
])
